











import { Component, Vue, Prop } from 'vue-property-decorator'
import { Mutation } from 'vuex-class'
import { MutationTypes } from '@/store/types'
import { State } from 'vuex-class'
import { RootState } from '@/store/types'

@Component
export default class SubmissionUploadSuccess extends Vue {
  @Prop()
  readonly allowRemoveNotification!: boolean

  @State((state: RootState) => state.hasUploadSubmitted)
  hasUploadSubmitted!: boolean

  @Mutation(MutationTypes.SET_UPLOAD_SUBMITTED)
  setHasUploadSubmitted!: (hasUploadSubmitted: boolean) => void
}






























































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { SubmissionStatusCircle } from '@/components/Shared'
import { BvTableFieldArray } from 'bootstrap-vue'
import { DutchSubmissionStatus } from '@/enums/submission'

import { 
  dateYearAgo, 
  dateToday, 
  dateTomorrow, 
  dateYearFromTomorrow, 
  dateYearAhead } from '@/utils/date'
import { RootState } from '@/store/types'
import { State } from 'vuex-class'
import SubmissionPopover from '@/components/SubmissionUpload/SubmissionPopover.vue'

const DateRangeTypesArray = ['past', 'future'] as const
type DateRangeTypes = typeof DateRangeTypesArray[number]

@Component({
  components: { SubmissionStatusCircle, SubmissionPopover }
})
export default class Table extends Vue {
  @State((state: RootState) => state.hospitalHasMultipleParts)
  hospitalHasMultipleParts!: boolean
  
  @Prop({ required: true })
  readonly title!: string

  @Prop({ required: true })
  readonly isSubmitted!: boolean

  @Prop({
    type: String,
    validator(status: DateRangeTypes) {
      return DateRangeTypesArray.includes(status)
    }
  })
  readonly dateRange!: DateRangeTypes

  @Prop({ required: true })
  readonly tableHeadVariant!: string

  @Prop(String)
  readonly tableVariant!: string

  @Prop({ type: Number, default: 0 })
  readonly visibleItems!: number

  @Prop({ type: Number, default: 0 })
  readonly totalItems!: number

  @Prop({ type: Array, default: [] })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly items!: any[]

  @Prop(String)
  readonly emptyText!: string

  sortBy = 'deadline'
  isDescending = false

  baseFields: BvTableFieldArray = [
    {
      key: 'stream',
      label: 'Aanleverstroom',
      thClass: 'col-w-150',
      sortable: true
    },
    {
      key: 'substream',
      label: 'Deelaanlevering',
      thClass: 'col-w-150',
      sortable: true
    },
    {
      key: 'period',
      label: 'Periode',
      thClass: 'col-w-150',
      sortable: true
    },
    { key: 'upload', thClass: 'col-w-150' }
  ]

  get fields() {
    const fields = [...this.baseFields]
    if(this.hospitalHasMultipleParts){
      fields.unshift(
        {
          key: 'disCode',
          label: 'INSTELLINGONDERDEEL',
          thClass: 'col-w-150',
          sortable: true
        }
      )
    }
    return fields
  }
  get failedSubmissionStatus() {
    return {
      Status: [
        DutchSubmissionStatus.Afgekeurd,
        DutchSubmissionStatus.VerwerktMetFouten
      ],
      IsLastProdSubmission: true
    }
  }

  get expectedSubmissionRange() {
    if (this.dateRange === 'past') {
      return {
        FromDate: dateYearAgo(),
        ToDate: dateToday()
      }
    } else if (this.dateRange === 'future') {
      return {
        FromDate: dateTomorrow(),
        ToDate: dateYearFromTomorrow()
      }
    } else {
      return {}
    }
  }

  get expectedFields() {
    const fields = [...this.fields]

    fields.splice(-1, 0, {
      key: 'deadline',
      thClass: 'col-w-150',
      sortable: true
    })

    return fields
  }

  get submittedFields() {
    const fields = [...this.fields]

    fields.splice(
      -1,
      0,
      {
        key: 'channel',
        label: 'Kanaal',
        thClass: 'col-w-150',
        sortable: true
      },
      // TODO: Bug #8700
      // {
      //   key: 'username',
      //   label: 'Gebruikersnaam',
      //   thClass: 'col-w-150',
      //   sortable: true
      // },
      {
        key: 'submissionDate',
        label: 'Aanleverdatum',
        thClass: 'col-w-150',
        sortable: true
      },
      { key: 'status', label: 'STATUS' , thClass: 'col-w-200', sortable: true },
      { key: 'details', label: 'DETAILS', thClass: 'col-w-150' }
    )

    return fields
  }
}

















import { Prop, Vue, Component } from 'vue-property-decorator'
import { ManualSubmission } from '@/components/SubmissionUpload/interfaces/submission.ts'
import { CheckboxFormGroup } from '@/components/SubmissionUpload/SubmissionForm/FormGroups'
import { isTest } from '@/types/test'

@Component({
  components: { CheckboxFormGroup }
})
export default class IsTest extends Vue {
  @Prop({ type: Object, required: true })
  readonly submission!: ManualSubmission

  get label(): isTest {
    const value = this.submission.isTest
      ? this.$t('form.checkbox.true')
      : this.$t('form.checkbox.false')
    return value as isTest
  }
}

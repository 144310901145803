






























import { Component, Vue } from 'vue-property-decorator'
import { DetailTable, DetailReport } from '@/components/SubmissionDetails'
import DatahubApi from '@/api/datahub.ts'
import {
  ExtendedSubmissionStatus,
  DutchSubmissionStatus
} from '@/types/submission'
import { getExtendedSubmissionStatusType } from '@/utils/submission.ts'
@Component({
  components: { DetailTable, DetailReport }
})
export default class SubmissionDetails extends Vue {
  detailTableStepperSteps: ExtendedSubmissionStatus[] = []
  detailTableTableItems: DatahubPortaalAPIComponents.Schemas.SubmissionFileDetailsResponse[] = []
  isLoading = true
  goBack = () => {return window.history.length > 2}
  async created() {
    try {
      const { data: submission } = await DatahubApi.getSubmissionProgress<
        DatahubPortaalAPIComponents.Schemas.SubmissionDetailsResponse
      >(this.$route.params.id)
      if (Array.isArray(submission.files)) {
        this.detailTableTableItems = [...submission.files]
      }
      this.detailTableStepperSteps = [
        getExtendedSubmissionStatusType({
          status: submission.status as DutchSubmissionStatus | null | undefined
        })
      ]
    } finally {
      this.isLoading = false
    }
  }
}

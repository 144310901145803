









































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import SubmissionPopover from '@/components/SubmissionUpload/SubmissionPopover.vue'
import { ReviewFormGroup } from '@/components/SubmissionUpload/SubmissionForm/FormGroups'
import { AutomaticSubmission } from '@/components/SubmissionUpload/interfaces/submission.ts'
import FormMixin from '@/components/SubmissionUpload/SubmissionForm/Mixins/FormMixin.vue'
import { isTest } from '@/types/test'
import { formatDate } from '@/utils/date'

@Component({
  components: {
    SubmissionPopover,
    ReviewFormGroup
  }
})
export default class ReviewForm extends Mixins(FormMixin) {
  @Prop({ type: Object, required: true })
  readonly submission!: AutomaticSubmission

  get isTestMappedType(): isTest {
    const value = this.submission.isTest
      ? this.$t('form.checkbox.true')
      : this.$t('form.checkbox.false')
    return value as isTest
  }

  get isResubmissionLabel() {
    if (!this.isResubmission) {
      return null
    }

    return this.submission.isResubmission
      ? this.$t('form.checkbox.true')
      : this.$t('form.checkbox.false')
  }

  get isResubmission() {
    return this.submission.isResubmission
  }

  formatDate(date: string) {
    return formatDate(date)
  }
}

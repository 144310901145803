

























import { Component, Vue } from 'vue-property-decorator'
import { SplitScreenNavigation } from '@/components/LandingPage'

@Component({
  components: { SplitScreenNavigation }
})
export default class LandingPage extends Vue {}

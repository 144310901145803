





























































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { ManualSubmission } from '@/components/SubmissionUpload/interfaces/submission.ts'
import FormMixin from '@/components/SubmissionUpload/SubmissionForm/Mixins/FormMixin.vue'
import {
  AGB,
  Part,
  Stream,
  Substream,
  Version,
  Period,
  IsTest,
  CreationDate,
  SequenceNumber,
  IsResubmission
} from '@/components/SubmissionUpload/SubmissionForm/ManualFormFields'

@Component({
  components: {
    AGB,
    Part,
    Stream,
    Substream,
    Version,
    Period,
    IsTest,
    CreationDate,
    SequenceNumber,
    IsResubmission
  }
})
export default class ManualForm extends Mixins(FormMixin) {
  validationStatePart = false
  validationStateStream = false
  validationStateSubstream = true // is an optional field
  validationStateVersion = false
  validationStatePeriod = true // is an optional field
  validationStateCreationDate = false
  validationStateSequenceNumber = false

  @Prop({ type: Object, required: true })
  readonly submission!: ManualSubmission

  get selectedStream() {
    return this.submission.inputOptions.streams.find(
      stream => stream.name === this.submission.stream
    )
  }

  get selectedSubstream() {
    return this.selectedStream?.substreams.length === 1
      ? this.selectedStream?.substreams[0]
      : this.selectedStream?.substreams.find(
          stream => stream.name === this.submission.substream
        )
  }

  get includeResubmission() {
    return this.selectedStream?.includeResubmission
  }

  get validationState() {
    return (
      this.validationStatePart &&
      this.validationStateStream &&
      this.validationStateSubstream &&
      this.validationStateVersion &&
      this.validationStatePeriod &&
      this.validationStateCreationDate &&
      this.validationStateSequenceNumber
    )
  }

  @Watch('validationState')
  onValidationStateChanged(newVal: boolean) {
    this.$emit('validation-state-changed', newVal, this.submission.fileName)
  }
}

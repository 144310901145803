






































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class RemovalOverview extends Vue {
  loading = false
  sortBy = 'deadline'
  isDescending = true

  items = [
    {
      id: 1,
      stream: 'A',
      part: 'X',
      deadline: '11-08-2020',
      data: 'X wordt verwijderd'
    },
    {
      id: 2,
      stream: 'B',
      part: 'Y',
      deadline: '31-12-2020',
      data: 'B wordt verwijderd'
    }
  ]

  fields = [
    {
      key: 'stream',
      label: 'Aanleverstroom',
      thClass: 'col-w-150',
      sortable: true
    },
    {
      key: 'part',
      label: 'Deelaanlevering',
      thClass: 'col-w-150',
      sortable: true
    },
    { key: 'deadline', thClass: 'col-w-150', sortable: true },
    { key: 'data', thClass: 'col-w-200', sortable: false }
  ]
}

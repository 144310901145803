import { requestApolloCMS } from '@/api/utils'
import { MijnDhdFilter } from '@/models/MijnDhdFilter'
import { CmsApi as CMSApi } from 'dhd-library/src/index'

import filterQuery from '@/api/queries/filters.gql'
import newsQuery from '@/api/queries/news.gql'
import infoBarQuery from '@/api/queries/infoBar.gql'

export default class implements CMSApi {
  public getFilters() {
    return requestApolloCMS<MijnDhdFilter[]>({ query: filterQuery })
  }

  public getNews() {
    return requestApolloCMS({ query: newsQuery })
  }

  public async getInfoBar() {
    return requestApolloCMS<any>({
      query: infoBarQuery,
      variables: { productId: `{"ProductId": "Toelichting Datahub Portaal"}` }
    }).then(response => {
      // The hotfix version of the dhd-library expects a different format of the infobar.
      return {
        ...response,
        infobar: {
          ...response.infobar[0],
          infoText: response.infobar[0].infoText.html
        }
      }
    })
  }

  public getInformatieBalk(parameters: { ProductId: string }) {
    return Promise.reject(
      `getInformatieBalk is called with ${parameters} should not be used`
    )
  }
}

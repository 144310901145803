import { isValid, parse, getISOWeeksInYear } from 'date-fns'

export type PeriodFormat = 'Y' | 'H' | 'Q' | 'M' | 'W' | 'D' | 'T'

const validateYear = (value: string) => {
  return isValid(parse(value)) && value.length === 4
}

const validateHalf = (value: string) => {
  const parts = value.split('H')
  const year = parts[0]
  const half = parseInt(parts[1])
  return ( 
    parts.length === 2 && 
    isValid(parse(year)) && 
    year.length === 4 && 
    half >= 1 && 
    half <= 2
  )
}

const validateQuarter = (value: string) => {
  const parts = value.split('K')
  const year = parts[0]
  const quarter = parseInt(parts[1])

  return (
    parts.length === 2 && 
    isValid(parse(year)) && 
    year.length === 4 && 
    quarter >= 1 && 
    quarter <= 4
  )
}

const validateWeek = (value: string) => {
  const parts = value.split('W')
  const year = parts[0]
  const week = parseInt(parts[1])
  return (
    parts.length === 2 &&
    isValid(parse(year)) &&
    year.length === 4 &&
    week >= 1 &&
    week <= getISOWeeksInYear(year)
  )
}

const validateTotal = (value: string) => {
  return value === 'Totaal'
}

export const validate = ({
  value,
  periodFormat
}: {
  value: string
  periodFormat: PeriodFormat
}) => {
  switch (periodFormat) {
    case 'Y':
      return validateYear(value)
    case 'H':
      return validateHalf(value)
    case 'Q':
      return validateQuarter(value)
    case 'M':
      return isValid(parse(value))
    case 'W':
      return validateWeek(value)
    case 'D':
      return isValid(parse(value))
    case 'T':
      return validateTotal(value)
    default:
      throw new Error('Invalid "periodFormat"')
  }
}

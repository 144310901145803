import {
  AutomaticSubmission,
  BaseSubmission,
  ManualSubmission
} from '@/components/SubmissionUpload/interfaces/submission.ts'

export const getAutomaticSubmission = ({
  fileName,
  validatedInput
}: {
  fileName: BaseSubmission['fileName']
  validatedInput: DatahubPortaalAPIComponents.Schemas.InputValidationResponse
}): AutomaticSubmission => ({
  fileName,
  errors: validatedInput.errors,
  isFileNameFormatValid: validatedInput.isFileNameFormatValid,
  ...(validatedInput.parsedInput as DatahubPortaalAPIComponents.Schemas.ParsedInputResponse)
})

export const getManualSubmission = ({
  fileName,
  validatedInput
}: {
  fileName: BaseSubmission['fileName']
  validatedInput: DatahubPortaalAPIComponents.Schemas.InputValidationResponse
}): ManualSubmission => ({
  fileName,
  errors: validatedInput.errors,
  isFileNameFormatValid: validatedInput.isFileNameFormatValid,
  inputOptions: validatedInput.inputOptions as DatahubPortaalAPIComponents.Schemas.InputOptionsResponse,
  get agb() {
    return this.inputOptions.agb
  },
  part: null,
  stream: null,
  substream: null,
  version: null,
  period: '',
  isTest: false,
  sequenceNumber: null,
  creationDate: null,
  isResubmission: false,
  validationState: false
})

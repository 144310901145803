/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAxiosInstance } from 'dhd-auth'
import { AxiosInstance } from 'axios'
import { getEnvironmentVariable, Scopes } from '@/utils/environment'
import defaultResponseInterceptor from '@/axios/interceptors/response/default/defaultResponseInterceptor'

const axiosInstance: AxiosInstance = createAxiosInstance(
  {
    baseURL: `${getEnvironmentVariable('VUE_APP_USER_API')}/api`
  },
  Scopes.user
)

defaultResponseInterceptor(axiosInstance)

export default class {
  public static getUserRights(): Promise<any> {
    return axiosInstance.request({
      url: `User/GetUserRights`
    })
  }
}

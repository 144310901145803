// API response status field
export enum DutchSubmissionStatus {
  Nieuw = 'In verwerking',
  Gepauzeerd = 'In verwerking',
  InVerwerking = 'In verwerking',
  Aangeleverd = 'Aangeleverd',
  Verwerkt = 'Verwerkt',
  VerwerktMetFouten = 'Verwerkt met fouten',
  VerwerktMetWaarschuwingen = 'Verwerkt met waarschuwingen',
  Afgekeurd = 'Afgekeurd',
  DeelsAangeleverd = 'Deels aangeleverd'
}

























import { Component, Prop, Vue } from 'vue-property-decorator'
import SubmissionPopover from '@/components/SubmissionUpload/SubmissionPopover.vue'

@Component({
  components: { SubmissionPopover }
})
export default class SubmissionForm extends Vue {
  @Prop({ type: String, required: true })
  readonly submissionPopoverContent!: string

  @Prop({ type: String, required: true })
  readonly submissionPopoverValue!: string

  @Prop({ type: Boolean, default: false })
  readonly isDirty!: boolean

  @Prop({ type: Boolean, default: null })
  readonly validationState!: boolean

  @Prop({ type: Boolean, default: false })
  readonly renderAsSwitch!: boolean

  @Prop({ type: String, required: true })
  readonly checkboxName!: string

  @Prop(String)
  readonly checkboxLabel!: string

  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly value!: any // https://bootstrap-vue.org/docs/components/form-checkbox#comp-ref-b-form-checkbox-props

  get localValue() {
    return this.value
  }
  set localValue(newVal) {
    this.$emit('input', newVal)
  }
}

















import {
  ManualSubmission,
  AutomaticSubmission
} from '@/components/SubmissionUpload/interfaces/submission.ts'
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class SubmissionForm extends Vue {
  @Prop(Object)
  readonly submission!: ManualSubmission | AutomaticSubmission
}


















import { Component, Vue } from 'vue-property-decorator'
import { State, Mutation } from 'vuex-class'
import { RootState, MutationTypes } from '@/store/types'

@Component
export default class AppLayoutLocalError extends Vue {
  @State((state: RootState) => state.localError)
  error!: string | null

  @Mutation(MutationTypes.SET_LOCAL_ERROR)
  clearError!: (error: string | null) => void

  get showAlert() {
    return this.error ? true : false
  }
}

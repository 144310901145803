








import Vue from 'vue'
import {
  DatahubPortaalBadRequestTypes,
  DatahubPortaalBadRequestTypesArray
} from '@/interfaces/datahub-portaal.ts'

export default Vue.extend({
  functional: true,
  props: {
    errorType: {
      type: String,
      validator(status: DatahubPortaalBadRequestTypes) {
        return DatahubPortaalBadRequestTypesArray.includes(status)
      },
      required: true
    }
  }
})






















import Vue from 'vue'

export default Vue.extend({
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    iconPlacement: {
      type: String,
      default: 'above'
    },
    spinner: {
      type: Boolean
    },
    theme: {
      type: String,
      default: 'primary'
    }
  }
})

import { DatahubApi } from '@/api'
import { FilterOption } from '@/models'

export interface Filters {
  streams: FilterOption[]
  substreams: FilterOption[]
  deadline?: FilterOption[]
  channels?: FilterOption[]
  statuses?: FilterOption[]
  submissionDate?: FilterOption[]
  usernames?: FilterOption[]
  environments?: FilterOption[]
  periods?: FilterOption[]
  disCodes?: FilterOption[]
}

function toFilterOptions(
  values: string[] | null | undefined
): FilterOption[] | undefined {
  if (values) {
    return values.map(value => {
      return { value, label: value }
    })
  }
}

function mapFilters(
  filtersResponse: DatahubPortaalAPIComponents.Schemas.SubmissionFiltersResponse
): Filters {
  return {
    streams: toFilterOptions(filtersResponse.streams) ?? [],
    substreams: toFilterOptions(filtersResponse.substreams) ?? [],
    channels: toFilterOptions(filtersResponse.channels),
    statuses: toFilterOptions(filtersResponse.statuses),
    usernames: toFilterOptions(filtersResponse.usernames),
    environments: toFilterOptions(filtersResponse.environments),
    periods: toFilterOptions(filtersResponse.periods),
    disCodes: toFilterOptions(filtersResponse.disCodes)
  }
}

export async function fetchExpectedSubmissionFilters(): Promise<Filters> {
  const {
    data: filtersResponse
  } = await DatahubApi.getExpectedSubmissionsFilters<
    DatahubPortaalAPIComponents.Schemas.SubmissionFiltersResponse
  >()
  const filters = mapFilters(filtersResponse)
  return filters
}

export async function fetchSubmissionFilters(): Promise<Filters> {
  const { data: filtersResponse } = await DatahubApi.getSubmissionsFilters<
    DatahubPortaalAPIComponents.Schemas.SubmissionFiltersResponse
  >()
  const filters = mapFilters(filtersResponse)
  return filters
}

export async function fetchFilteredExpectedFilters(
  parameters: DatahubPortaalAPIPaths.ApiExpectedSubmissions.Get.FilterQueryParameters
): Promise<Filters> {
  const {
    data: filteredFilters
  } = await DatahubApi.getExpectedSubmissionsFiltersFiltered<
    DatahubPortaalAPIComponents.Schemas.SubmissionFiltersResponse
  >(parameters)
  const filters = mapFilters(filteredFilters)
  return filters
}

export async function fetchFilteredFilters(
  parameters: DatahubPortaalAPIPaths.ApiSubmissions.Get.FilterQueryParameters
): Promise<Filters> {
  const {
    data: filteredFilters
  } = await DatahubApi.getSubmissionsFiltersFiltered<
    DatahubPortaalAPIComponents.Schemas.SubmissionFiltersResponse
  >(parameters)
  const filters = mapFilters(filteredFilters)
  return filters
}

import Vue from 'vue'

import { date, time } from 'dhd-library/src/filters/time'

type nullableString = string | null
const datetime = (value?: nullableString): nullableString => {
    if (!value) return null
    const date = new Date(value)
    const minutes = ('0' + date.getMinutes()).slice(-2)
    const hours = ('0' + date.getHours()).slice(-2)
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${hours}:${minutes}`
  }

Vue.filter('date', date)
Vue.filter('time', time)
Vue.filter('datetime', datetime)

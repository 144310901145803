import { RootState } from './types'

export const initState = (): RootState => ({
  initialized: false,
  user: null,
  hospitalHasMultipleParts: false,
  isAuthenticated: false,
  globalError: null,
  localError: null,
  authError: null,
  hasUploadSubmitted: false
})

export default initState

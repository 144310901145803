
















import { Component, Prop, Vue } from 'vue-property-decorator'
import SubmissionPopover from '@/components/SubmissionUpload/SubmissionPopover.vue'

/**
 * Review (non-editable/disabled) form group
 */
@Component({
  components: { SubmissionPopover }
})
export default class SubmissionForm extends Vue {
  @Prop({ type: String, required: true })
  readonly submissionPopoverContent!: string

  @Prop({ type: String, required: true })
  readonly submissionPopoverValue!: string

  @Prop({ type: String, required: true })
  readonly inputName!: string

  @Prop({ type: [Number, String], required: true })
  readonly inputValue!: string | number
}

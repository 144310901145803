











import { Component, Mixins } from 'vue-property-decorator'
import { SubmissionOverview } from '@/components/SubmissionOverview'
import FilterMixin, {
  Filters
} from '@/components/SubmissionOverview/mixins/FilterMixin.vue'
import { fetchExpectedSubmissionFilters } from '@/services/submissionFilterService'

@Component({
  components: { SubmissionOverview }
})
export default class SubmissionOverviewExpected extends Mixins(FilterMixin) {
  isFetchingFilters = true

  filters: Filters = {
    streams: [],
    substreams: [],
    deadline: []
  }
  async created() {
    try {
      const filters = await fetchExpectedSubmissionFilters()
      this.setFilters(filters)

      return filters
    } finally {
      this.isFetchingFilters = false
    }
  }
}

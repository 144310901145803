


















import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { ManualSubmission } from '@/components/SubmissionUpload/interfaces/submission.ts'
import { SelectFormGroup } from '@/components/SubmissionUpload/SubmissionForm/FormGroups'
import { BFormSelect } from '@/interfaces/bootstrap-vue/b-form-select'

import { generateBFormSelectOptionsGroup } from '@/utils/bootstrap-vue.ts'

@Component({
  components: { SelectFormGroup }
})
export default class Substream extends Vue {
  @Prop({ type: Object, required: true })
  readonly submission!: ManualSubmission

  @Prop({ type: Object, default: undefined })
  readonly selectedStream!: DatahubPortaalAPIComponents.Schemas.StreamOptionResponse

  isDirty = false

  get select() {
    return this.selectedStream
      ? generateBFormSelectOptionsGroup<
          DatahubPortaalAPIComponents.Schemas.SubstreamOptionResponse
        >({
          array: this.selectedStream.substreams,
          propertyName: 'name',
          selectedValue: this.submission.substream
        })
      : null
  }

  get validationState() {
    return typeof this.submission.substream === 'string'
  }

  @Watch('select')
  onSelectChanged(newVal: BFormSelect) {
    if (newVal.options.length === 1) {
      this.$emit('substream-changed', newVal.selected)
      this.isDirty = true
    }
  }

  @Watch('validationState')
  onValidationStateChanged(newVal: boolean) {
    this.$emit('validation-state-changed', newVal)
  }
}
















import { Prop, Vue, Component } from 'vue-property-decorator'
import { ManualSubmission } from '@/components/SubmissionUpload/interfaces/submission.ts'
import { CheckboxFormGroup } from '@/components/SubmissionUpload/SubmissionForm/FormGroups'

@Component({
  components: { CheckboxFormGroup }
})
export default class IsResubmission extends Vue {
  @Prop({ type: Object, required: true })
  readonly submission!: ManualSubmission
}

import { BFormSelect } from '@/interfaces/bootstrap-vue/b-form-select.ts'

export const generateBFormSelectOptionsGroup = <T extends {}>({
  array,
  propertyName,
  selectedValue = null
}: {
  array: T[]
  propertyName: keyof T
  selectedValue?: string | null
}): BFormSelect => {
  const select = array.reduce(
    // eslint-disable-next-line max-params
    (acc, item) => {
      if (typeof item[propertyName] === 'string') {
        acc.options.push((item[propertyName] as unknown) as string)
      }
      return acc
    },
    {
      options: [],
      selected: selectedValue
    } as BFormSelect
  )

  if (select.options.length === 1 && !selectedValue) {
    select.selected = select.options[0] as string
  }

  return select
}

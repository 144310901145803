import Vue from 'vue'
import Vuex from 'vuex'

import initState from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

// import * as modules from './modules'

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)

export default new Vuex.Store({
  state: initState(),
  mutations,
  actions,
  getters,
  // modules,
  strict: debug
})

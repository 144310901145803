import { RequiredField } from '@/utils/typescript'
import { AxiosError } from 'axios'

/**
 * Type guard: is error of type AxiosError with response object?
 */
export const isAxiosErrorWithResponse = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
): error is RequiredField<AxiosError, 'response'> =>
  (error as AxiosError).isAxiosError === true &&
  typeof (error as AxiosError).response !== 'undefined'

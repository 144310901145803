







import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class NavbarLinkOut extends Vue {
  @Prop({ default: '', type: String }) readonly url!: string;
  @Prop({ default: '_blank', type: String }) readonly target!: string;
  htmlTargets: string[] = ["_blank", "_self", "_parent", "_top"]; // alle html waarden voor geldige target

  navigate() {
    if (this.htmlTargets.includes(this.target)) {
      window.open(this.url, this.target);
    } else {
      window.location.href = this.url;
    }
  }
}

























import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { ManualSubmission } from '@/components/SubmissionUpload/interfaces/submission.ts'
import { InputFormGroup } from '@/components/SubmissionUpload/SubmissionForm/FormGroups'

import {
  validate as validatePeriod,
  PeriodFormat
} from '@/components/SubmissionUpload/SubmissionForm/utils/period-format-validation.ts'

@Component({
  components: { InputFormGroup }
})
export default class Period extends Vue {
  @Prop({ type: Object, required: true })
  readonly submission!: ManualSubmission

  @Prop({ type: Object, default: undefined })
  readonly selectedSubstream!: DatahubPortaalAPIComponents.Schemas.SubstreamOptionResponse

  isDirty = false

  get periodFormat() {
    return this.selectedSubstream?.options.find(
      option => option.version === this.submission.version
    )?.periodFormat
  }

  get validationState() {
    // period is not mandatory
    if (!this.periodFormat) {
      return true
    }

    if (this.submission.period === '' || this.submission.period === null) {
      return false
    }

    return validatePeriod({
      value: this.submission.period,
      periodFormat: this.periodFormat as PeriodFormat
    })
  }

  @Watch('validationState')
  onValidationStateChanged(newVal: boolean) {
    this.$emit('validation-state-changed', newVal)
  }
}

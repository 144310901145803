import { createAxiosInstance } from 'dhd-auth'
import { AxiosInstance } from 'axios'
import { MijnDhdApi, MijnDhdResponse } from 'dhd-library/src/mijn-dhd-api'
import { getEnvironmentVariable, Scopes } from '@/utils/environment'

export class MijnDhdApiImpl implements MijnDhdApi {
  public appName = 'Datahub-portaal'

  public async getMijnDhdData(): Promise<MijnDhdResponse> {
    const result = await this.createAxiosInstance().get<MijnDhdResponse>(
        'api/menu'
        );
    return result.data;
  }

  public async setUserThemeId(id: number): Promise<void> {
    await this.createAxiosInstance().patch('api/user/theme/' + id)
  }

  private createAxiosInstance(): AxiosInstance{
    return createAxiosInstance({
        baseURL: getEnvironmentVariable('VUE_APP_MIJNDHD_API')
    }, Scopes.mijnDhd)
  }
}
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SubmissionPopover extends Vue {
  @Prop(String)
  readonly content!: string
  @Prop(Boolean)
  readonly useTableColor!: boolean
}


import { Component, Vue } from 'vue-property-decorator'

interface FilterOption {
  value: number | string
  label: string
}

export interface Filters {
  streams: FilterOption[]
  substreams: FilterOption[]
  deadline?: FilterOption[]
  channels?: FilterOption[]
  statuses?: FilterOption[]
  submissionDate?: FilterOption[]
  usernames?: FilterOption[]
  environments?: FilterOption[]
  disCodes?: FilterOption[]
}

@Component
export default class FilterMixin extends Vue {
  isFetchingFilters = true

  filters: Filters = {
    streams: [],
    substreams: []
  }

  async setFilters(filters: Filters) {
    for (const [key, values] of Object.entries(filters)) {
      Vue.set(this.filters, key, values)
    }
  }
}

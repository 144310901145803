














import { Component, Mixins } from 'vue-property-decorator'
import { SubmissionOverview } from '@/components/SubmissionOverview'
import { dateToday, dateYearAgo } from '@/utils/date'
import FilterMixin, { Filters } from '@/components/SubmissionOverview/mixins/FilterMixin.vue'
import { fetchSubmissionFilters } from '@/services/submissionFilterService'
import SubmissionUploadSuccess from '@/views/SubmissionUploadSuccessPage.vue'
import { State } from 'vuex-class'
import { RootState } from '@/store/types'


@Component({
  components: { SubmissionOverview, SubmissionUploadSuccess}
})

export default class SubmissionOverviewSubmitted extends Mixins(FilterMixin) {
  @State((state: RootState) => state.hasUploadSubmitted)
  hasUploadSubmitted!: boolean


  isFetchingFilters = true

  filters: Filters = {
    streams: [],
    substreams: [],
    deadline: [],
    channels: [],
    statuses: [],
    submissionDate: [],
    usernames: [],
    environments: []
  }
 
  get lastYear() {
    return dateYearAgo()
  }

  get today() {
    return dateToday()
  }


  
  async created() {
    try {
      const filters = await fetchSubmissionFilters()
      this.setFilters(filters)

      return filters
    } finally {
      this.isFetchingFilters = false
    }
  }
}

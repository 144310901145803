













































import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { RootState, ActionTypes } from '@/store/types'
import { AppScreen, AppLayout, AppLayoutAuthError } from '@/components'
import { UserInfo } from '@/models'
import { DatahubApi } from '@/api'
import { SignOutService } from '@/services'

@Component({
  components: { AppLayout, AppScreen, AppLayoutAuthError }
})
export default class App extends Vue {
  @State((state: RootState) => state.initialized)
  initialized!: boolean

  @State((state: RootState) => state.globalError)
  globalError!: string | null

  @State((state: RootState) => state.authError)
  authError!: string | null

  @Action(ActionTypes.INITIALIZE_STATE)
  initializeState!: () => Promise<UserInfo | undefined>

  showAlert = false
  variant = 'primary'
  message = ''
  userName = ''

  async created() {
    try {
      const { data } = await DatahubApi.getUserInfo()
      this.userName = data.userName

      await this.initialize()
    } catch (error) {
      console.log(error)
    }
  }

  async initialize() {
    if (!this.initialized) {
      await this.initializeState()
    }
  }

  startSignOut() {
    SignOutService.signOutUser()
  }
}

import {
  DutchSubmissionStatus,
  ExtendedSubmissionStatus
} from '@/types/submission'

export const getExtendedSubmissionStatusType = ({
  status
}: {
  status?: DutchSubmissionStatus | null
}): ExtendedSubmissionStatus => {
  switch (status) {
    case 'Nieuw':
    case 'Gepauzeerd':
    case 'In verwerking':
      return 'InProgress'
    case 'Verwerkt':
      return 'Processed'
    case 'Verwerkt met fouten':
      return 'ProcessedUnsuccessful'
    case 'Verwerkt met waarschuwingen':
      return 'ProcessedWithWarnings'
    case 'Afgekeurd':
      return 'Rejected'
    case 'Deels aangeleverd':
      return 'PartiallyDelivered'
    default:
      throw new Error(`Unknown status: ${status}`)
  }
}

import Vue from 'vue'
import VueApollo from 'vue-apollo'
import fetch from 'cross-fetch'
import { ApolloClient, DefaultOptions } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { getAccessToken } from 'dhd-auth'

import { getEnvironmentVariable } from '@/utils/environment'

// HTTP connection to the cms API
const cmsLink = createHttpLink({
  // Use an absolute URL
  uri: `${getEnvironmentVariable('VUE_APP_CMS_API')}/api/graphql`,
  fetch
})

// HTTP connection to the user API
const userLink = createHttpLink({
  // Use an absolute URL
  uri: `${getEnvironmentVariable('VUE_APP_USER_API')}/graphql`,
  fetch
})


const cmsAuthLink = setContext(async (_, { headers }) => {
  const token = await getAccessToken(
    `https://${getEnvironmentVariable(
      'VUE_APP_DOMAIN'
    )}/${getEnvironmentVariable('VUE_APP_CMS_CLIENT')}/graphuser_impersonation`)
  return {
    headers: {
      ...headers,
      authorization: token.accessToken ? `Bearer ${token.accessToken}` : ''
    }
  };
});

// eslint-disable-next-line max-params
const authLink = setContext(async (_, { headers }) => {
  const { accessToken } = await getAccessToken(
    `https://${getEnvironmentVariable(
      'VUE_APP_DOMAIN'
    )}/${getEnvironmentVariable('VUE_APP_USER_CLIENT')}/user_impersonation`
  )

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : ''
    }
  }
})

const defaultOptions: DefaultOptions = {
  // Documentation: https://www.apollographql.com/docs/react/api/react-hoc/#optionsfetchpolicy
  query: {
    fetchPolicy: 'no-cache'
  }
}

// Create the apollo multi-clients
export const apolloClientCMS = new ApolloClient({
  link: cmsAuthLink.concat(cmsLink),
  cache: new InMemoryCache(),
  connectToDevTools: true
})

export const apolloClientUsers = new ApolloClient({
  link: authLink.concat(userLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: defaultOptions
})

// Create apollo provider
const apolloProvider = new VueApollo({
  clients: {
    apolloClientCMS,
    apolloClientUsers
  },
  defaultClient: apolloClientCMS
})

// Install the vue plugin
Vue.use(VueApollo)

export default apolloProvider







































































































import { Component, Vue } from 'vue-property-decorator'
import { ItemsProviderContext } from '@/interfaces/bootstrap-vue/b-table'
import { BTable } from 'bootstrap-vue'
import { DatahubApi } from '@/api'
import { Mutation } from 'vuex-class'
import { MutationTypes } from '@/store/types'

import { BvTableField } from 'node_modules/bootstrap-vue/src/components/table/index'
import { FieldIndex } from '@/enums/detail-report'
import { cloneDeep } from 'lodash'
import { formatDateTime } from '@/utils/date'
import XLSX from 'xlsx'
import SubmissionPopover from '@/components/SubmissionUpload/SubmissionPopover.vue'

@Component({
  components: { SubmissionPopover }
})
export default class DetailReport extends Vue {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  @Mutation(MutationTypes.SET_LOCAL_ERROR)
  setLocalError!: (error: string | null) => void

  // Number of rows per page
  perPage: number | string = 100

  // 	Total number of rows in the dataset
  totalRows: number | string = 0

  // Current page number, starting from 1
  currentPage: number | string = 1

  // <b-table> automatically tracks/controls its busy state
  // https://bootstrap-vue.org/docs/components/table#automated-table-busy-state
  isBusy = false

  fileName: string | null = null
  substream?: string | null = null
  period?: string | null = null
  link: string | null = null
  submissionDate = ''

  fields: Array<{ key: string } & BvTableField> = [
    { key: 'RecordId', label: this.$t('labels.recordId') as string },
    { key: 'Error', label: this.$t('labels.error').toString().toUpperCase() as string },
    { key: 'ErrorCode', label: this.$t('labels.errorCode') as string },
    { key: 'ErrorDescription', label: this.$t('labels.description') as string }
  ]

  get extendedFields() {
    const clonedFields = cloneDeep(this.fields)
    return [
      { key: 'fileName', label: this.$t('labels.source') as string },
      { key: 'substream', label: this.$t('labels.substream') as string },
      { key: 'period', label: this.$t('labels.period') as string },
      { key: 'submissionDate', label: this.$t('labels.submissionDate') as string },
      { key: 'link', label: this.$t('labels.link') as string },
      ...clonedFields
    ]
  }

  async mounted() {
    this.currentPage = 1

    const table = this.$refs.table as BTable

    if (table) {
      table.refresh()
    }
  }

  formatDateTime(date: string) {
    return formatDateTime(date)
  }

  async getSubmissionProgressReport(
    id: string,
    query: DatahubPortaalAPIPaths.ApiSubmissions$IdReport.Get.QueryParameters
  ) {
    const { data: report } = await DatahubApi.getSubmissionProgressReport<
      DatahubPortaalAPIComponents.Schemas.SubmissionReportResponse
    >(id, query)
    return report
  }

  async getTotalRecords() {
    const queryParams = {
      PageNumber: 1,
      PageSize: this.totalRows as number
    }

    const report = await this.getSubmissionProgressReport(
      this.$route.params.id,
      queryParams
    )

    return report.data ? report.data : []
  }

  async itemsProvider(ctx: ItemsProviderContext) {
    try {
      const queryParams = {
        PageNumber: ctx.currentPage,
        PageSize: ctx.perPage
      }

      const report = await this.getSubmissionProgressReport(
        this.$route.params.id,
        queryParams
      )
      this.link = report.link
      this.fileName = report.fileName
      this.substream = report.substream
      this.period = report.period
      this.submissionDate =
        (report.submissionDate as string) ?? this.submissionDate

      this.totalRows = report.violationsTotalCount as number

      if (!Array.isArray(report.data)) {
        return []
      }

      if (report.dynamicHeaders) {
        this.fields = this.insertDynamicHeaders({
          fields: this.fields,
          headers: report.dynamicHeaders
        })
      }
      return [...report.data]
    } catch {
      return []
    }
  }

  async exportAsCSV() {
    await this.exportFile(
      `${this.$t('components.DetailReport.title')}_${this.fileName}.csv`
    )
  }

  async exportFile(name: string) {
    try {
      const workbook = XLSX.utils.book_new()

      const data = await this.getTotalRecords()
      const mapped = this.mapDataToFields(data)

      mapped.unshift(this.getTableHeaderLabels())

      const worksheet = XLSX.utils.aoa_to_sheet(mapped)

      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, name)
    } catch {
      this.setLocalError(
        this.$t('api.DatahubPortaal.error.export-error') as string
      )
    }
  }

  insertDynamicHeaders({
    fields,
    headers
  }: {
    fields: Array<{ key: string } & BvTableField>
    headers: string[]
  }) {
    const mappedHeaders = headers.map(header => {
      return { key: header }
    })

    const clonedFields = cloneDeep(fields)
    clonedFields.splice(FieldIndex.RecordId + 1, 0, ...mappedHeaders)
    return clonedFields
  }

  mapDataToFields(data: any[]) {
    const extendedData = data.map(item => {
      return {
        fileName: this.fileName,
        substream: this.substream,
        period: this.period,
        link: this.link,
        submissionDate: this.submissionDate,
        ...item
      }
    })

    return extendedData.map(item => {
      return this.extendedFields.map(
        (field: { key: string } & BvTableField) => {
          return field.key
            .split('.')
            .reduce(
              (acc: any, cur: string) =>
                acc[cur] === undefined ? 0 : acc[cur],
              item
            )
        }
      )
    })
  }

  getTableHeaderLabels() {
    return this.extendedFields.map(f => (f.label ? f.label : f.key))
  }
}

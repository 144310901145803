
























import { Component, Prop, Vue } from 'vue-property-decorator'
import SubmissionPopover from '@/components/SubmissionUpload/SubmissionPopover.vue'

@Component({
  components: { SubmissionPopover }
})
export default class SubmissionForm extends Vue {
  @Prop({ type: String, required: true })
  readonly submissionPopoverContent!: string

  @Prop({ type: String, required: true })
  readonly submissionPopoverValue!: string

  @Prop({ type: Object })
  readonly attributes!: object

  @Prop({
    required: true,
    type: String,
    validator(type: string) {
      return [
        'text',
        'number',
        'email',
        'password',
        'search',
        'url',
        'tel',
        'date',
        'time',
        'range',
        'color'
      ].includes(type)
    }
  })
  readonly type!: string

  @Prop({ type: Boolean, default: false })
  readonly isDirty!: boolean

  @Prop({ type: Boolean, default: null })
  readonly validationState!: boolean

  @Prop({ type: String, required: true })
  readonly inputName!: string

  @Prop({ type: String })
  readonly groupDescription!: string

  @Prop({ type: [String, Number] })
  readonly value!: string | number
}

























import { Component, Prop, Vue } from 'vue-property-decorator'
import SubmissionPopover from '@/components/SubmissionUpload/SubmissionPopover.vue'
import { DatePicker } from '@/components/Shared'

@Component({
  components: { SubmissionPopover, DatePicker }
})
export default class SubmissionForm extends Vue {
  @Prop({ type: String, required: true })
  readonly submissionPopoverContent!: string

  @Prop({ type: String, required: true })
  readonly datePickerId!: string

  @Prop({ type: String, required: true })
  readonly datePickerLabel!: string

  @Prop({ type: String })
  readonly datePickerMinDate!: string

  @Prop({ type: String })
  readonly datePickerMaxDate!: string

  @Prop({ type: String })
  readonly datePickerDateOne!: string

  @Prop({ type: String, required: true })
  readonly submissionPopoverValue!: string

  @Prop({ type: Boolean, default: false })
  readonly isDirty!: boolean

  @Prop({ type: Boolean, default: null })
  readonly validationState!: boolean

  @Prop({ type: String, required: true })
  readonly inputName!: string
}

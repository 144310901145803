




















import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { ManualSubmission } from '@/components/SubmissionUpload/interfaces/submission.ts'
import { SelectFormGroup } from '@/components/SubmissionUpload/SubmissionForm/FormGroups'
import { BFormSelect } from '@/interfaces/bootstrap-vue/b-form-select'
import { generateBFormSelectOptionsGroup } from '@/utils/bootstrap-vue.ts'

@Component({
  components: { SelectFormGroup }
})
export default class Version extends Vue {
  @Prop({ type: Object, required: true })
  readonly submission!: ManualSubmission

  @Prop({ type: Object, default: undefined })
  readonly selectedSubstream!: DatahubPortaalAPIComponents.Schemas.SubstreamOptionResponse

  isDirty = false

  get fileType() : string | undefined {
    return this.submission.fileName.split('.').pop()?.toLowerCase()
  }

  get versionsByFileType() {
    return this.selectedSubstream.options.filter(x => x.fileFormat.toLowerCase() === this.fileType)
  }

  get select() {    
    return this.selectedSubstream
      ? generateBFormSelectOptionsGroup<
          DatahubPortaalAPIComponents.Schemas.ChannelOptionsResponse
        >({
          array: this.versionsByFileType,
          propertyName: 'version',
          selectedValue: this.submission.version
        })
      : null
  }

  get validationState() {
    return typeof this.submission.version === 'string'
  }

  @Watch('select')
  onSelectChanged(newVal: BFormSelect) {
    if (newVal.options.length === 1) {
      this.$emit('version-changed', newVal.selected)
      this.isDirty = true
    }
  }

  @Watch('validationState')
  onValidationStateChanged(newVal: boolean) {
    this.$emit('validation-state-changed', newVal)
  }
}


































import Vue from 'vue'

export default Vue.extend({
  functional: true,
  props: {
    errors: {
      type: Array,
      required: true
    }
  }
})









































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ExtendedSubmissionStatus } from '@/types/submission.ts'

type StepMap = {
  [K in Exclude<ExtendedSubmissionStatus, 'Expected'>]: StepMapItem
}

interface StepMapItem {
  variant: string
  icon: string
  content: string
  label: string
}

@Component
export default class Stepper extends Vue {
  @Prop({
    type: Array,
    default: [],
    validator(val) {
      return val && val.length > 0
    }
  })
  readonly steps!: ExtendedSubmissionStatus[]

  @Prop({ type: Number, default: 1 })
  readonly currentStep!: number

  get scaleX() {
    let step = this.currentStep
    if (step < 0) {
      step = 0
    } else if (step >= this.steps.length) {
      step = this.steps.length - 1
    }
    return step / (this.steps.length - 1)
  }

  stepMap: StepMap = {
    Delivered: {
      variant: 'success',
      icon: 'check-circle',
      content: this.$t(
        'views.SubmissionDetails.tabVoortgang.stepper.delivered.content'
      ) as string,
      label: this.$t(
        'views.SubmissionDetails.tabVoortgang.stepper.delivered.label'
      ) as string
    },
    InProgress: {
      variant: 'primary',
      icon: 'clock',
      content: this.$t(
        'views.SubmissionDetails.tabVoortgang.stepper.inProgress.content'
      ) as string,
      label: this.$t(
        'views.SubmissionDetails.tabVoortgang.stepper.inProgress.label'
      ) as string
    },
    Processed: {
      variant: 'success',
      icon: 'check-circle',
      content: this.$t(
        'views.SubmissionDetails.tabVoortgang.stepper.processed.content'
      ) as string,
      label: this.$t(
        'views.SubmissionDetails.tabVoortgang.stepper.processed.label'
      ) as string
    },
    ProcessedUnsuccessful: {
      variant: 'warning',
      icon: 'exclamation-circle',
      content: this.$t(
        'views.SubmissionDetails.tabVoortgang.stepper.processedUnsuccessful.content'
      ) as string,
      label: this.$t(
        'views.SubmissionDetails.tabVoortgang.stepper.processedUnsuccessful.label'
      ) as string
    },
    ProcessedWithWarnings: {
      variant: 'warning',
      icon: 'exclamation-circle',
      content: this.$t(
        'views.SubmissionDetails.tabVoortgang.stepper.processedWithWarnings.content'
      ) as string,
      label: this.$t(
        'views.SubmissionDetails.tabVoortgang.stepper.processedWithWarnings.label'
      ) as string
    },
    Rejected: {
      variant: 'danger',
      icon: 'times-circle',
      content: this.$t(
        'views.SubmissionDetails.tabVoortgang.stepper.rejected.content'
      ) as string,
      label: this.$t(
        'views.SubmissionDetails.tabVoortgang.stepper.rejected.label'
      ) as string
    },
    PartiallyDelivered: {
      variant: 'warning',
      icon: 'exclamation-circle',
      content: this.$t(
        'views.SubmissionDetails.tabVoortgang.stepper.partiallyDelivered.content'
      ) as string,
      label: this.$t(
        'views.SubmissionDetails.tabVoortgang.stepper.partiallyDelivered.label'
      ) as string
    }
  }

  stepOne: ExtendedSubmissionStatus = 'Delivered'
}

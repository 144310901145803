















































import { Component, Vue } from 'vue-property-decorator'
import { DatahubApi } from '@/api'

@Component
export default class SubmissionZone extends Vue {
  file1: File | null = null

  max = 100
  uploadPercentage = 0

  //  formatNames () {
  //   return this.files.length === 1 ? this.files[0].name : `${this.files.length} bestanden geselecteerd`
  // }

  //  removeFile (name: string) {
  //   this.files = this.files.filter(f => f.name !== name)
  // }

  async upload() {
    if (!this.file1) {
      return
    }

    const { data: validatedInput } = await DatahubApi.uploadValidateInput<
      DatahubPortaalAPIComponents.Schemas.InputValidationResponse
    >({
      onUploadProgress: (progressEvent: ProgressEvent) => {
        this.uploadPercentage = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        )
      },
      params: {
        fileName: this.file1.name
      }
    })

    this.$emit('upload', {
      file: this.file1,
      validatedInput
      // use to test:
      // validatedInput: {
      //   isFileNameFormatValid: false,
      //   errors: null,
      //   parsedInput: null,
      //   inputOptions: {
      //     agb: '44444444',
      //     parts: ['1'],
      //     streams: [
      //       {
      //         name: 'MMY',
      //         includeResubmission: true,
      //         substreams: [
      //           {
      //             name: 'IKNL',
      //             options: [{ version: '1.0', periodFormat: null }]
      //           },
      //           {
      //             name: 'PROMS',
      //             options: [{ version: '1.0', periodFormat: null }]
      //           }
      //         ]
      //       }
      //     ]
      //   }
      // }
    })
  }
}

/* eslint-disable max-params */

import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import {
  LandingPage,
  RemovalOverviewPage,
  SubmissionPage,
  SubmissionDetailsPage,
  SubmissionHomePage,
  SubmissionOverviewExpectedPage,
  SubmissionOverviewSubmittedPage,
  SubmissionUploadPage,
  SubmissionUploadSuccessPage,
  RequestPage,
  RequestOverviewPage,
  NotFoundPage
} from '@/views'
import { UserApi } from '@/api'
import { SecurityGroup } from '@/config/security-groups'
import { MutationTypes } from '@/store/types'
import store from '@/store/index'
Vue.use(VueRouter)

const aangeleverd = 'aangeleverd'

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    beforeEnter: async (to, from, next) => {
      const { data: rights } = await UserApi.getUserRights()
      const maySubmit = rights.some(
        (r: string) => r === SecurityGroup.Data.Submit
      )
      const mayRequest = rights.some(
        (r: string) => r === SecurityGroup.Data.Request
      )

      if (maySubmit && !mayRequest) {
        next('/aanlevering/overzicht')
      } else if (!maySubmit && mayRequest) {
        next('/afname/overzicht')
      } else {
        next()
      }
    },
    component: LandingPage
  },
  {
    path: '/aanlevering/',
    component: SubmissionPage,
    beforeEnter: async (to, from, next) => {
      const { data: rights } = await UserApi.getUserRights()
      if (rights.some((r: string) => r === SecurityGroup.Data.Submit)) {
        next()
      } else {
        next(false)
      }
    },
    children: [
      {
        path: 'overzicht',
        name: 'overzicht',
        component: SubmissionHomePage
      },
      {
        path: 'aanleveren',
        name: 'bestanden aanleveren',
        component: SubmissionUploadPage
      },
      {
        path: 'aanleveren/succes',
        name: 'bestanden aanleveren succesvol',
        component: SubmissionUploadSuccessPage
      },
      {
        path: 'details/:id',
        name: 'aanleverdetails',
        component: SubmissionDetailsPage
      },
      {
        path: 'verwacht',
        name: 'verwacht',
        component: SubmissionOverviewExpectedPage
      },
      {
        path: aangeleverd,
        name: aangeleverd,
        component: SubmissionOverviewSubmittedPage
      }
    ]
  },
  {
    path: '/afname/',
    component: RequestPage,
    beforeEnter: async (to, from, next) => {
      const { data: rights } = await UserApi.getUserRights()
      if (rights.some((r: string) => r === SecurityGroup.Data.Request)) {
        next()
      } else {
        next(false)
      }
    },
    children: [
      {
        path: 'overzicht',
        name: 'afnameoverzicht',
        component: RequestOverviewPage
      }
    ]
  },
  {
    path: '/verwijderd',
    name: 'verwijderoverzicht',
    component: RemovalOverviewPage
  },
  // 404 not found
  { path: '*', component: NotFoundPage }
]

const  vueRoute =  new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

 vueRoute.beforeEach((to, from, next) => {
  if (from.name === aangeleverd && to.name !== aangeleverd) {
   store.commit(MutationTypes.SET_UPLOAD_SUBMITTED, false)
  }
  next()
})

export default vueRoute























import { Component, Prop, Vue } from 'vue-property-decorator'
import { BFormSelect } from '@/interfaces/bootstrap-vue/b-form-select'
import SubmissionPopover from '@/components/SubmissionUpload/SubmissionPopover.vue'

@Component({
  components: { SubmissionPopover }
})
export default class SubmissionForm extends Vue {
  @Prop({ type: String, required: true })
  readonly submissionPopoverContent!: string

  @Prop({ type: String, required: true })
  readonly submissionPopoverValue!: string

  @Prop({ type: Object, required: true })
  readonly select!: BFormSelect

  @Prop({ type: Boolean, default: false })
  readonly isDirty!: boolean

  @Prop({ type: Boolean, default: null })
  readonly validationState!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: String, required: true })
  readonly inputName!: string
}

import { DatahubApi } from '@/api'
import { RootActions, MutationTypes, ActionTypes } from './types'

export const actions: RootActions = {
  async [ActionTypes.INITIALIZE_STATE]({ commit }) {
    try {
      const { data: user } = await DatahubApi.getUserInfo()
      const { data: hospitalHasMultipleParts } = await DatahubApi.getHospitalHasMultipleParts()
      commit(MutationTypes.SET_INITIAL_STATE, { user, hospitalHasMultipleParts, hasUploadSubmitted: false })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      commit(MutationTypes.SET_GLOBAL_ERROR, error.message)
    }
  }
}

export default actions

/* eslint-disable max-params */
import { RootMutations, MutationTypes } from './types'

export const mutations: RootMutations = {
  [MutationTypes.SET_INITIAL_STATE]: (state, { user, hospitalHasMultipleParts, hasUploadSubmitted }) => {
    state.initialized = true
    state.user = user
    state.hospitalHasMultipleParts = hospitalHasMultipleParts
    state.hasUploadSubmitted = hasUploadSubmitted
  },
  [MutationTypes.SET_UPLOAD_SUBMITTED]: (state, hasUploadSubmitted) => {
    state.hasUploadSubmitted = hasUploadSubmitted
  },
  [MutationTypes.SET_AUTH_STATUS]: (state, isAuthenticated) => {
    state.isAuthenticated = isAuthenticated
  },
  [MutationTypes.SET_GLOBAL_ERROR]: (state, error) => {
    state.initialized = true
    state.globalError = error
  },
  [MutationTypes.SET_LOCAL_ERROR]: (state, error) => {
    state.localError = error
  },
  [MutationTypes.SET_AUTH_ERROR]: (state, error) => {
    state.authError = error
  }
}

export default mutations

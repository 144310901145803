






































import { Component, Vue } from 'vue-property-decorator'
import { Table as SubmissionTable } from '@/components/SubmissionHome'
import { DatahubApi } from '@/api'
import { dateTimeYearAgo, dateTimeToday, dateTimeYearAhead } from '@/utils/date'
import { DutchSubmissionStatus } from '@/enums/submission'

const homepagePayload = {
  PageNumber: 1,
  PageSize: 5
}

@Component({
  components: {
    SubmissionTable
  }
})
export default class SubmissionHome extends Vue {
  isLoading = true

  missing: DatahubPortaalAPIComponents.Schemas.SubmissionsResponse = {
    submissions: [],
    totalCount: 0
  }
  upcoming: DatahubPortaalAPIComponents.Schemas.SubmissionsResponse = {
    submissions: [],
    totalCount: 0
  }
  failed: DatahubPortaalAPIComponents.Schemas.SubmissionsResponse = {
    submissions: [],
    totalCount: 0
  }

  async created() {
    try {
      const { data: missing } = await DatahubApi.getExpectedSubmissions<
        DatahubPortaalAPIComponents.Schemas.SubmissionsResponse
      >({
        ...homepagePayload,
        FromDate: dateTimeYearAgo(),
        ToDate: dateTimeToday()
      })

      const { data: upcoming } = await DatahubApi.getExpectedSubmissions<
        DatahubPortaalAPIComponents.Schemas.SubmissionsResponse
      >({
        ...homepagePayload,
        FromDate: dateTimeToday(),
        ToDate: dateTimeYearAhead()
      })

      const failedSubmissionsPayload: DatahubPortaalAPIPaths.ApiSubmissions.Get.QueryParameters = {
        ...homepagePayload,
        IsLastProdSubmission: true,
        Status: [
          DutchSubmissionStatus.Afgekeurd,
          DutchSubmissionStatus.VerwerktMetFouten
        ]
      }

      const { data: failed } = await DatahubApi.getSubmissions<
        DatahubPortaalAPIComponents.Schemas.SubmissionsResponse
      >(failedSubmissionsPayload)

      this.missing = missing
      this.upcoming = upcoming
      this.failed = failed
    } finally {
      this.isLoading = false
    }
  }
}

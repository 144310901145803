import { apolloClientCMS, apolloClientUsers } from '@/apollo'
import { OperationVariables } from 'apollo-client/core/types'
import { DocumentNode } from 'graphql'

// CMS API
export async function requestApolloCMS<T>({
  query,
  variables
}: {
  query: DocumentNode
  variables?: OperationVariables
}): Promise<T> {
  const result = await apolloClientCMS.query<T>({ query, variables })

  if (result.errors) {
    throw new Error(result.errors.join(','))
  }

  return result.data
}

// User API
export async function requestApolloUsers<T>({
  query,
  variables
}: {
  query: DocumentNode
  variables?: OperationVariables
}): Promise<T> {
  const result = await apolloClientUsers.query<T>({ query, variables })

  if (result.errors) {
    throw new Error(result.errors.join(','))
  }

  return result.data
}

export async function mutationApollo<T>({
  query,
  variables
}: {
  query: DocumentNode
  variables?: OperationVariables
}): Promise<T> {
  const result = await apolloClientUsers.mutate({ mutation: query, variables })
  if (result.errors) {
    throw new Error(result.errors.join(','))
  }
  return result.data
}

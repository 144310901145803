
import { Component, Vue } from 'vue-property-decorator'

@Component
class FormMixin extends Vue {
  collapseModel = true

  /**
   * Force collapse to close
   * This method will be invoked by the parent via $ref
   */
  closeCollapse() {
    this.collapseModel = false
  }
}
export default FormMixin

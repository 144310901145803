var render = function (_h,_vm) {var _c=_vm._c;return _c('font-awesome-icon',{class:{
    'text-danger': _vm.props.status === 'Afgekeurd',
    'text-success':
      _vm.props.status === 'Aangeleverd' ||
      _vm.props.status === 'In verwerking' ||
      _vm.props.status === 'Verwerkt',
    'text-warning': 
      _vm.props.status === 'Verwerkt met fouten' ||
      _vm.props.status === 'Verwerkt met waarschuwingen' ||
      _vm.props.status === 'Deels aangeleverd'
  },attrs:{"icon":"circle"}})}
var staticRenderFns = []

export { render, staticRenderFns }
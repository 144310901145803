export const DatahubPortaalBadRequestTypesArray = [
  'validation-error',
  'submission-already-exists-error',
  'internal-error',
  'not-found-error',
  'not-authenticated-error',
  'not-authorized-error',
  'invalid-file-name-parts-count'
] as const

export type DatahubPortaalBadRequestTypes = typeof DatahubPortaalBadRequestTypesArray[number]

export interface DatahubPortaalBadRequest {
  Type: DatahubPortaalBadRequestTypes
  Title: null | string
  Status: number
}

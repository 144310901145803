





















































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ItemsProviderContext } from '@/interfaces/bootstrap-vue/b-table'
import { BTable } from 'bootstrap-vue'
import { debounce } from 'lodash'
import { Dictionary } from 'vue-router/types/router'
import { DatePicker, SubmissionStatusCircle } from '@/components/Shared'
import { DatahubApi } from '@/api'
import { BvTableFieldArray } from 'bootstrap-vue'
import {
  fetchFilteredExpectedFilters,
  fetchFilteredFilters,
  Filters
} from '@/services/submissionFilterService'
import { RootState } from '@/store/types'
import { State } from 'vuex-class'
import SubmissionPopover from '@/components/SubmissionUpload/SubmissionPopover.vue'

function toEnvironmentLabel(value: string): string {
  return value.toUpperCase() === 'PROD'
    ? 'Productie'
    : value.toUpperCase() === 'TEST'
    ? 'Test'
    : value
}

@Component({
  components: { DatePicker, SubmissionStatusCircle, SubmissionPopover }
})
export default class SubmissionOverview extends Vue {
  @State((state: RootState) => state.hospitalHasMultipleParts)
  hospitalHasMultipleParts!: boolean

  @Prop({ type: String, required: true, default: '' })
  readonly title!: string

  @Prop({ type: Boolean, required: true, default: false })
  readonly isSubmitted!: boolean

  @Prop({ type: String, required: false, default: '' })
  readonly minDate!: string

  @Prop({ type: String, required: false, default: '' })
  readonly maxDate!: string

  @Prop({ type: Boolean, required: true, default: true })
  readonly isFetchingFilters!: boolean

  @Prop({ type: Object, required: true })
  readonly filters!: Filters

  filteredFilters: Filters | null = null

  get availableFilters(): Filters {
    const available = this.filteredFilters ?? this.filters
    // return all filtered options, unless a filteredFilters instance is available (after filtering the first time)
    return {
      ...available,
      environments: available.environments?.map(e => {
        return {
          value: e.value,
          label: toEnvironmentLabel(e.label)
        }
      })
    }
  }

  // <b-table> automatically tracks/controls its busy state
  // https://bootstrap-vue.org/docs/components/table#automated-table-busy-state
  isBusy = false

  baseFields: BvTableFieldArray = [
    {
      key: 'stream',
      label: 'Aanleverstroom',
      thClass: 'col-w-150'
    },
    {
      key: 'substream',
      label: 'Deelaanlevering',
      thClass: 'col-w-150'
    },
    { key: 'deadline', thClass: 'col-w-150' },
    {
      key: 'period',
      label: 'Periode',
      thClass: 'col-w-150'
    },
    { key: 'upload', thClass: 'col-w-150' }
  ]

  get appendedBaseFields() {
    const fields = [...this.baseFields]
    if (this.hospitalHasMultipleParts)
    {
      fields.unshift(
      {
        key: 'disCode',
        label: 'INSTELLINGONDERDEEL',
        thClass: 'col-w-150'
      }
      )
    }
    return fields
  }

  get submittedFields() {
    const fields = [...this.appendedBaseFields]

    fields.splice(
      2,
      0,
      { 
        key: 'version', 
        label: 'Versie'
      },
      { key: 'isTest', label: 'Test' },
      {
        key: 'submissionDate',
        label: 'Aanleverdatum',
        thClass: 'col-w-150'
      }
    )

    fields.splice(
      -1,
      0,
      {
        key: 'channel',
        label: 'Kanaal',
        thClass: 'col-w-150'
      },
      { key: 'status', label: 'STATUS', thClass: 'col-w-200' },
      // TODO: Bug #8700
      // {
      //   key: 'username',
      //   label: 'Aangeleverd door',
      //   thClass: 'col-w-200',
      //   sortable: true
      // },
      { key: 'details',label: 'DETAILS', thClass: 'col-w-150' }
    )

    return fields
  }

  // Number of rows per page
  perPage: number | string = 10

  // 	Total number of rows in the dataset
  totalRows: number | string = 0

  // Current page number, starting from 1
  currentPage: number | string = 1

  selectedStream: string | null = null
  selectedSubstream: string | null = null
  selectedEnvironment: string | null = null
  selectedFromDate = ''
  selectedToDate = ''
  selectedChannel = ''
  selectedStatuses: string[] = []
  selectedUsernames: string[] = []
  selectedPeriods: string[] = []
  selectedDisCodes: string[] = []

  isLastProdSubmission: boolean | null = null

  @Watch('selectedStream')
  @Watch('selectedSubstream')
  @Watch('selectedEnvironment')
  @Watch('selectedChannel')
  @Watch('selectedStatuses')
  @Watch('selectedUsernames')
  @Watch('selectedPeriods')
  @Watch('selectedDisCodes')
  @Watch('isLastProdSubmission')
  updateSearchResults() {
    this.debouncedOnFilterChange()
  }

  mounted() {
    const {
      PageNumber: currentPageQueryParam,
      Stream: streamQueryParam,
      Substream: substreamQueryParam,
      Environment: environmentQueryParam,
      FromDate: fromDateQueryParam,
      ToDate: toDateQueryParam,
      Channel: channelQueryParam,
      Status: statusQueryParam, // TODO: could be multiple values
      Periods: periodsQueryParam,
      Usernames: userQueryParam,
      DisCodes: disCodesQueryParam,
      IsLastProdSubmission: latestSubmissionQueryParam
    } = this.$route.query as Dictionary<unknown>

    this.currentPage = (currentPageQueryParam as string) ?? this.currentPage

    this.selectedStream = (streamQueryParam as string) ?? this.selectedStream

    this.selectedSubstream =
      (substreamQueryParam as string) ?? this.selectedSubstream

    this.selectedEnvironment =
      (environmentQueryParam as string) ?? this.selectedEnvironment

    this.selectedFromDate =
      (fromDateQueryParam as string) ?? this.selectedFromDate

    this.selectedToDate = (toDateQueryParam as string) ?? this.selectedToDate

    this.selectedChannel = (channelQueryParam as string) ?? this.selectedChannel

    this.selectedStatuses =
      (statusQueryParam as string[]) ?? this.selectedStatuses

    this.selectedPeriods =
      (periodsQueryParam as string[]) ?? this.selectedPeriods

    this.selectedUsernames =
      (userQueryParam as string[]) ?? this.selectedUsernames
    
    this.selectedDisCodes = 
      (disCodesQueryParam as string[]) ?? this.selectedDisCodes

    this.isLastProdSubmission =
      (latestSubmissionQueryParam as boolean | null) ??
      this.isLastProdSubmission
  }

  debouncedOnFilterChange = debounce(this.onFilterChange, 0)

  onFilterChange() {
    this.currentPage = 1

    const table = this.$refs.table as BTable

    if (table) {
      table.refresh()
    }
  }
  async itemsProvider(ctx: ItemsProviderContext) {
    try {
      const payload = {
        PageNumber: ctx.currentPage,
        PageSize: ctx.perPage,
        Stream: this.selectedStream,
        Substream: this.selectedSubstream,
        Environment: this.selectedEnvironment,
        FromDate: this.selectedFromDate,
        ToDate: this.selectedToDate,
        Periods: this.selectedPeriods,
        DisCodes: this.selectedDisCodes,
      }
      if (!this.isSubmitted) {
        const queryParams = {
          ...payload,
          PageNumber: payload.PageNumber.toString(),
          PageSize: payload.PageSize.toString()
        }

        this.$router
          .replace({ query: { ...this.$route.query, ...queryParams } })
          // catch() to disable error "NavigationDuplicated: Avoided redundant navigation to current location"
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => {})

        const { data: response } = await DatahubApi.getExpectedSubmissions<
          DatahubPortaalAPIComponents.Schemas.SubmissionsResponse
        >(payload)

        this.filteredFilters = await fetchFilteredExpectedFilters(payload)

        this.totalRows = response.totalCount as number
        return response.submissions
      } else {
        const submittedPayload = {
          ...payload,
          Channel: this.selectedChannel,
          Status: this.selectedStatuses,
          Usernames: this.selectedUsernames,
          IsLastProdSubmission: this.isLastProdSubmission
        }

        const queryParams = {
          ...submittedPayload,
          PageNumber: submittedPayload.PageNumber.toString(),
          PageSize: submittedPayload.PageSize.toString(),
          IsLastProdSubmission: submittedPayload.IsLastProdSubmission?.toString()
        }

        this.$router
          .replace({ query: { ...this.$route.query, ...queryParams } })
          // catch() to disable error "NavigationDuplicated: Avoided redundant navigation to current location"
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => {})

        const { data: response } = await DatahubApi.getSubmissions<
          DatahubPortaalAPIComponents.Schemas.SubmissionsResponse
        >(submittedPayload)

        this.filteredFilters = await fetchFilteredFilters(submittedPayload)

        this.totalRows = response.totalCount as number
        return response.submissions
      }
    } catch {
      return []
    }
  }

  clearFilterValues() {
    this.selectedStream = null
    this.selectedSubstream = null
    this.selectedEnvironment = null
    this.selectedFromDate = ''
    this.selectedToDate = ''
    this.selectedChannel = ''
    this.selectedStatuses = []
    this.selectedUsernames = []
    this.selectedPeriods = []
    this.selectedDisCodes = []
    this.isLastProdSubmission = null
  }
}

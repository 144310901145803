










































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getEnvironmentVariable } from '@/utils/environment'

import { orderBy } from 'lodash'

const TagTypesArray = ['nieuws', 'evenementen'] as const
type TagTypes = typeof TagTypesArray[number]

@Component
export default class DhdRelevant extends Vue {
  @Prop(Boolean)
  readonly loading!: boolean

  @Prop({
    type: String,
    validator(tag: TagTypes) {
      return TagTypesArray.includes(tag)
    }
  })
  readonly tag!: TagTypes

  @Prop(String)
  readonly title!: string

  @Prop(Array)
  readonly items!: object[]

  get mijndhd() {
    return getEnvironmentVariable('VUE_APP_MIJNDHD')
  }

  get sortedItems() {
    if (this.tag === 'nieuws') {
      return orderBy(this.items, 'pubDate', 'desc')
    } else {
      return orderBy(this.items, 'date', 'asc')
    }
  }
}

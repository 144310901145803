
















import Vue from 'vue'
import { DutchSubmissionStatus } from '@/types/submission'
import { DutchSubmissionStatus as DutchSubmissionStatusEnum } from '@/enums/submission'

export default Vue.extend({
  functional: true,
  props: {
    status: {
      type: String,
      validator(status: DutchSubmissionStatus) {
        return Object.values(DutchSubmissionStatusEnum).includes(
          status as DutchSubmissionStatusEnum
        )
      },
      required: true
    }
  }
})

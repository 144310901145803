













































































































































import { Component, Vue } from 'vue-property-decorator'
import { SubmissionZone } from '@/components/SubmissionUpload'
import { State } from 'vuex-class'
import { RootState } from '@/store/types'
import { UserInfo } from '@/models'
import {
  AutomaticSubmission,
  BaseSubmission,
  ManualSubmission
} from '@/components/SubmissionUpload/interfaces/submission.ts'
import {
  getAutomaticSubmission,
  getManualSubmission
} from '@/components/SubmissionUpload/SubmissionForm/utils/submission.ts'
import ManualForm from '@/components/SubmissionUpload/SubmissionForm/Forms/ManualForm.vue'
import ReviewForm from '@/components/SubmissionUpload/SubmissionForm/Forms/ReviewForm.vue'
import SubmissionFormHeader from '@/components/SubmissionUpload/SubmissionForm/SubmissionFormHeader.vue'
import SubmissionUploadError from '@/components/SubmissionUpload/SubmissionUploadError.vue'
import SubmissionFormErrors from '@/components/SubmissionUpload/SubmissionForm/SubmissionFormErrors.vue'
import { DatahubApi } from '@/api'
import { isAxiosErrorWithResponse } from '@/axios/utils/axios.ts'
import { DatahubPortaalBadRequest } from '@/interfaces/datahub-portaal'
import { Mutation } from 'vuex-class'
import { MutationTypes } from '@/store/types'
import SubmissionPopover from '@/components/SubmissionUpload/SubmissionPopover.vue'
interface PerformUpload
  extends Omit<
    DatahubPortaalAPIComponents.Schemas.ParsedInputResponse,
    'period'
  > {
  fileName: string
  period:
    | DatahubPortaalAPIComponents.Schemas.ParsedInputResponse['period']
    | null
}

@Component({
  components: {
    SubmissionZone,
    ManualForm,
    ReviewForm,
    SubmissionFormHeader,
    SubmissionFormErrors,
    SubmissionUploadError,
    SubmissionPopover
  }
})
/**
 * Submission upload - upload a submission to the datahub backend
 *
 * # Data Flow
 * 1. The user uploads a file.
 * 2. Based on the response it is determined wether the file has errors or is a AutomaticSubmission or ManualSubmission:
 * 3a. If the file is not valid the errors will be displayed to the user and the user needs to (re)upload a file.
 * 3b. AutomaticSubmission - All fields are non-editable prefilled.
 * 3c. ManualSubmission - User has to manually fill all (required) fields.
 * 4. User click's on "controleren" and can review the submission(s).
 * 5. User click's on "aanleveren" and POST's the submission(s)
 * 6. Based on the response is is determined wether the submission has errors or is succesfull:
 * 7a. If the submission is not valid the error(s) will be displayed to the user and the user need to address the error(s)
 * 7b. If the submission is succesfull received the user will be redirected to the success page
 *  */
export default class SubmissionUpload extends Vue {
  @State((state: RootState) => {state.user, state.hasUploadSubmitted})
  user!: UserInfo
  hasUploadSubmitted!: boolean

  @Mutation(MutationTypes.SET_UPLOAD_SUBMITTED)
  setHasUploadSubmitted!: (hasUploadSubmitted: boolean) => void

  isLoading = false

  submissions: {
    automatic: AutomaticSubmission[]
    manual: ManualSubmission[]
  } = {
    automatic: [],
    manual: []
  }

  submissionZoneFiles: File[] = []

  isUserReviewing = false

  validatedValidationState: boolean | null = null

  uploadPercentage = 0

  get isEveryManualSubmissionValid() {
    const result = this.submissions.manual.every(
      manualSubmission => manualSubmission.validationState === true
    )
    return result
  }

  get isEverySubmissionUploadErrorFalse() {
    const isEveryManualSubmissionErrorTypeFalse = this.submissions.manual.every(
      manualSubmission =>
        typeof manualSubmission.uploadErrorType !== 'undefined'
    )
    const isEveryAutomaticSubmissionErrorTypeFalse = this.submissions.automatic.every(
      automaticSubmission =>
        typeof automaticSubmission.uploadErrorType !== 'undefined'
    )

    return Boolean(
      isEveryManualSubmissionErrorTypeFalse &&
        isEveryAutomaticSubmissionErrorTypeFalse
    )
  }

  get isValidationDisabled() {
    return (
      !(this.submissions.automatic.length || this.submissions.manual.length) ||
      !this.isEveryManualSubmissionValid
    )
  }

  onSubmit() {
    this.submissions.automatic.forEach(automaticSubmission =>
      this.performUpload(automaticSubmission)
    )
    this.submissions.manual.forEach(manualSubmission =>
      this.performUpload({
        fileName: manualSubmission.fileName,
        agb: manualSubmission.agb as string,
        part: manualSubmission.part as string,
        stream: manualSubmission.stream as string,
        substream: manualSubmission.substream,
        version: manualSubmission.version as string,
        period: manualSubmission.period,
        isTest: manualSubmission.isTest as boolean,
        creationDate: manualSubmission.creationDate as string,
        sequenceNumber: manualSubmission.sequenceNumber as number,
        isResubmission: manualSubmission.isResubmission
      })
    )
  }

  async performUpload({
    fileName,
    agb,
    part,
    stream,
    substream,
    version,
    period,
    isTest,
    creationDate,
    sequenceNumber,
    isResubmission
  }: PerformUpload) {
    const formData = new FormData()
    const file = this.findFileBySubmissionFileName(fileName)
    if (file) {
      formData.append('file', file)
    }
    formData.append('agb', agb)
    formData.append('part', part)
    formData.append('stream', stream)
    if (substream) {
      formData.append('substream', substream)
    }
    formData.append('version', version)
    if (period) {
      formData.append('period', period)
    }
    formData.append('isTest', JSON.stringify(isTest))
    formData.append('creationDate', creationDate)
    formData.append('sequenceNumber', sequenceNumber.toString())
    if (isResubmission) {
      formData.append('isResubmission', JSON.stringify(isResubmission))
    }

    this.isLoading = true

    try {
      await DatahubApi.upload<{}>({
        data: formData,
        config: {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            this.uploadPercentage = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            )
          }
        }
      })

      this.setHasUploadSubmitted(true)
      this.$router.push('aanleveren/succes')
    } catch (err) {
      if (isAxiosErrorWithResponse(err)) {
        const { data }: { data: DatahubPortaalBadRequest } = err.response
        const submission =
          this.findManualSubmissionByFileName(fileName) ||
          this.findAutomaticSubmissionByFileName(fileName)

        if (submission) {
          Vue.set(submission, 'uploadErrorType', data.Type)
        }

        this.submissions.automatic.forEach(automaticSubmission => {
          ;(this.$refs[
            `automatic-form-${automaticSubmission.fileName}`
          ] as ReviewForm[])[0].closeCollapse()
        })

        this.submissions.manual.forEach(manualSubmission =>
          (this.$refs[
            `manual-form-${manualSubmission.fileName}`
          ] as ManualForm[])[0].closeCollapse()
        )
      }
    } finally {
      this.isLoading = false
    }
  }

  onSubmissonZoneUpload({
    validatedInput,
    file
  }: {
    validatedInput: DatahubPortaalAPIComponents.Schemas.InputValidationResponse
    file: File
  }) {
    const isAutomaticMode = validatedInput?.parsedInput ? true : false

    this.submissions.automatic.splice(0)
    this.submissions.manual.splice(0)

    isAutomaticMode
      ? this.submissions.automatic.push(
          getAutomaticSubmission({
            fileName: file.name,
            validatedInput
          })
        )
      : this.submissions.manual.push(
          getManualSubmission({
            fileName: file.name,
            validatedInput
          })
        )

    this.submissionZoneFiles.splice(0)
    this.submissionZoneFiles.push(file)
  }

  findAutomaticSubmissionByFileName(fileName: BaseSubmission['fileName']) {
    return this.submissions.automatic.find(
      automaticSubmission => automaticSubmission.fileName === fileName
    )
  }

  findManualSubmissionByFileName(fileName: BaseSubmission['fileName']) {
    return this.submissions.manual.find(
      manualSubmission => manualSubmission.fileName === fileName
    )
  }

  findFileBySubmissionFileName(fileName: string) {
    return this.submissionZoneFiles.find(file => file.name === fileName)
  }

  setValueOnManualSubmission(
    ...args: [string, BaseSubmission['fileName'], keyof ManualSubmission]
  ) {
    const [$event, filename, property] = args
    const manualSubmission = this.findManualSubmissionByFileName(filename)
    if (manualSubmission) {
      Vue.set(manualSubmission, property, $event)
    }
  }

  setValueOnAutomaticSubmission(
    ...args: [string, BaseSubmission['fileName'], keyof AutomaticSubmission]
  ) {
    const [$event, filename, property] = args
    const automaicSubmission = this.findAutomaticSubmissionByFileName(filename)
    if (automaicSubmission) {
      Vue.set(automaicSubmission, property, $event)
    }
  }
  /**
   * Set the validationState for the specfic submission
   */
  onManualFormValidationStateChanged(
    ...args: [ManualSubmission['validationState'], ManualSubmission['fileName']]
  ) {
    const [validationState, fileName] = args
    const submission = this.findManualSubmissionByFileName(fileName)
    if (submission) {
      submission.validationState = validationState
    }
  }
}

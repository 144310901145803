







































import { Component, Watch, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { MijnDhdApi } from 'dhd-library/src/mijn-dhd-api'
import { RootState } from '@/store/types'
import { CmsApi, UserApi, MijnDhdApiImpl } from '@/api'
import { UserInfo } from '@/models'
import { SecurityGroup } from '@/config/security-groups'
import { getEnvironmentVariable } from '@/utils/environment'
import AppLayoutLocalError from './AppLayoutLocalError.vue'
import LockedMenu from './Shared/LockedMenu.vue'
import  { NavbarLinkOut } from '@/components/NavBarLinkout'
import { SignOutService } from '@/services'

interface Route {
  name: string
  path: string
  exact: boolean
}

@Component({
  components: { AppLayoutLocalError, LockedMenu, NavbarLinkOut }
})
export default class AppLayout extends Vue {
  @State((state: RootState) => state.user)
  user!: UserInfo

  openLeft = false
  openRight = false

  mijnDhdApi: MijnDhdApi = new MijnDhdApiImpl();

  appName = 'Datahub-portaal'

  navItems: Route[] = []

  setStatusFromLeft(status: boolean) {
    this.openRight = false
    this.openLeft = status
  }

  setStatusFromRight(status: boolean) {
    this.openLeft = false
    this.openRight = status
  }

  closeBars() {
    this.openLeft = this.openRight = false
  }

  @Watch('openLeft')
  @Watch('openRight')
  bodyCover() {
    if (this.openLeft || this.openRight) {
      document.querySelector('body')?.classList?.add('scrollbar--hide')
    } else {
      document.querySelector('body')?.classList?.remove('scrollbar--hide')
    }
  }

  get userApi() {
    return UserApi
  }

  get cmsApi() {
    return new CmsApi()
  }

  version = 'default'

  async created() {
    const { data: rights } = await UserApi.getUserRights()

    const maySubmit = rights.some(
      (r: string) => r === SecurityGroup.Data.Submit
    )
    if (maySubmit) {
      this.navItems.push(
        {
          name: 'overzicht',
          path: '/aanlevering/overzicht',
          exact: false
        },
        {
          name: 'aangeleverd',
          path: '/aanlevering/aangeleverd',
          exact: false
        },
        {
          name: 'verwacht',
          path: '/aanlevering/verwacht',
          exact: false
        },
        {
          name: 'bestanden aanleveren',
          path: '/aanlevering/aanleveren',
          exact: false
        }
      )
    }

    const mayRequest = rights.some(
      (r: string) => r === SecurityGroup.Data.Request
    )
    if (mayRequest) {
      this.navItems.push({
        name: 'afnameoverzicht',
        path: '/afname/overzicht',
        exact: false
      })
    }

    if (maySubmit && mayRequest) {
      this.navItems.unshift({ name: 'home', path: '/', exact: true })
    }
  }

  mounted() {
    this.version = getEnvironmentVariable('VUE_APP_BUILD_VERSION')
  }

  async startSignOut() {
    await SignOutService.signOutUser()
  }
}




















import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { ManualSubmission } from '@/components/SubmissionUpload/interfaces/submission.ts'
import { DatePickerFormGroup } from '@/components/SubmissionUpload/SubmissionForm/FormGroups'
import { dateTimeYearAgo } from '@/utils/date'

@Component({
  components: { DatePickerFormGroup }
})
export default class CreationDate extends Vue {
  @Prop({ type: Object, required: true })
  readonly submission!: ManualSubmission

  isDirty = false

  get dateTimeYearAgo() {
    return dateTimeYearAgo()
  }

  get validationState() {
    return Boolean(
      typeof this.submission.creationDate === 'string' &&
        this.submission.creationDate.length
    )
  }

  @Watch('validationState')
  onValidationStateChanged(newVal: boolean) {
    this.$emit('validation-state-changed', newVal)
  }
}























import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { ManualSubmission } from '@/components/SubmissionUpload/interfaces/submission.ts'
import { InputFormGroup } from '@/components/SubmissionUpload/SubmissionForm/FormGroups'

@Component({
  components: { InputFormGroup }
})
export default class SequenceNumber extends Vue {
  @Prop({ type: Object, required: true })
  readonly submission!: ManualSubmission

  isDirty = false

  get validationState() {
    return (
      typeof this.submission.sequenceNumber === 'number' &&
      this.submission.sequenceNumber >= 1 &&
      this.submission.sequenceNumber <= 100
    )
  }

  @Watch('validationState')
  onValidationStateChanged(newVal: boolean) {
    this.$emit('validation-state-changed', newVal)
  }
}

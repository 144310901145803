











































import { Stepper } from '@/components/Shared'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ExtendedSubmissionStatus } from '@/types/submission'
import { BvTableFieldArray } from 'bootstrap-vue'
import SubmissionPopover from '@/components/SubmissionUpload/SubmissionPopover.vue'


@Component({
  components: { Stepper, SubmissionPopover }
})
export default class DetailTable extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  readonly tableItems!: DatahubPortaalAPIComponents.Schemas.SubmissionFileDetailsResponse[]

  @Prop({
    type: Array,
    required: true,
    default: []
  })
  readonly stepperSteps!: ExtendedSubmissionStatus[]

  sortBy = 'fileName'

  currentStep = 2

  isDescending = false

  fields : BvTableFieldArray = [
    {
      key: 'fileName',
      label: 'Bron',
      thClass: 'col-w-150',
      sortable: true
    },
    {
      key: 'processedRecords',
      label: '# RECORDS VERWERKT',
      thClass: 'col-w-150',
      sortable: false
    },
    {
      key: 'nonProcessedRecords',
      label: '# RECORDS NIET VERWERKT',
      thClass: 'col-w-150',
      sortable: false
    },
    {
      key: 'errors',
      label: '# fouten',
      thClass: 'col-w-150',
      sortable: false
    },
    {
      key: 'warnings',
      label: '# waarschuwingen',
      thClass: 'col-w-150',
      sortable: false
    }
  ]
}

/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAxiosInstance } from 'dhd-auth'
import { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios'
import defaultResponseInterceptor from '@/axios/interceptors/response/default/defaultResponseInterceptor'

import { getEnvironmentVariable, Scopes } from '@/utils/environment'
import Qs from 'qs'
import { UserInfo } from '@/models'

const axiosConfig = {
  baseURL: `${getEnvironmentVariable('VUE_APP_DATAHUB_API')}/api`
}

const axiosInstance: AxiosInstance = createAxiosInstance(axiosConfig, Scopes.datahub)

defaultResponseInterceptor(axiosInstance)

const axiosInstanceWithoutGlobalErrorHandling: AxiosInstance = createAxiosInstance(axiosConfig, Scopes.datahub)

export default class {
  public static getUserInfo(): AxiosPromise<UserInfo> {
    return axiosInstance.get<UserInfo>('/user/user-info')
  }

  public static getHospitalHasMultipleParts(): AxiosPromise<boolean>{
    return axiosInstance.get<boolean>('/hospital/hasMultipleParts')
  }

  public static uploadValidateInput<T>(
    config: AxiosRequestConfig
  ): AxiosPromise<T> {
    return axiosInstance.post<T>('/upload/validate-input', null, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      ...config
    })
  }

  public static upload<T>({
    data,
    config
  }: {
    data: FormData
    config: AxiosRequestConfig
  }): AxiosPromise<T> {
    return axiosInstanceWithoutGlobalErrorHandling.post<T>('/upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      ...config
    })
  }

  public static getExpectedSubmissionsFilters<T>(): AxiosPromise<T> {
    return axiosInstance.get<T>('/expected-submissions/filters')
  }

  public static getExpectedSubmissionsFiltersFiltered<T>(
    payload: DatahubPortaalAPIPaths.ApiSubmissions.Get.FilterQueryParameters
  ): AxiosPromise<T> {
    return axiosInstance.get<T>('/expected-submissions/filteredfilters', {
      params: payload,
      paramsSerializer: params => {
        return Qs.stringify(params, { indices: false, arrayFormat: 'repeat' })
      }
    })
  }

  public static getExpectedSubmissions<T>(
    payload: DatahubPortaalAPIPaths.ApiExpectedSubmissions.Get.QueryParameters
  ): AxiosPromise<T> {
    return axiosInstance.get<T>('/expected-submissions', {
      params: payload,
      paramsSerializer: params => {
        return Qs.stringify(params, { indices: false, arrayFormat: 'repeat' })
      }
    })
  }

  public static getSubmissionsFilters<T>(): AxiosPromise<T> {
    return axiosInstance.get<T>('/submissions/filters')
  }

  public static getSubmissionsFiltersFiltered<T>(
    payload: DatahubPortaalAPIPaths.ApiSubmissions.Get.FilterQueryParameters
  ): AxiosPromise<T> {
    return axiosInstance.get<T>('/submissions/filteredfilters', {
      params: payload,
      paramsSerializer: params => {
        return Qs.stringify(params, { indices: false, arrayFormat: 'repeat' })
      }
    })
  }

  public static getSubmissions<T>(
    payload: DatahubPortaalAPIPaths.ApiSubmissions.Get.QueryParameters
  ): AxiosPromise<T> {
    return axiosInstance.get<T>('/submissions', {
      params: payload,
      paramsSerializer: params => {
        return Qs.stringify(params, { indices: false, arrayFormat: 'repeat' })
      }
    })
  }

  public static getSubmissionProgress<T>(id: string): AxiosPromise<T> {
    return axiosInstance.get<T>(`/submissions/${id}`)
  }

  public static getSubmissionProgressReport<T>(
    id: string,
    queryParams: DatahubPortaalAPIPaths.ApiSubmissions$IdReport.Get.QueryParameters
  ): AxiosPromise<T> {
    return axiosInstance.get<T>(`/submissions/${id}/report`, {
      params: queryParams,
      paramsSerializer: params => {
        return Qs.stringify(params, { indices: false, arrayFormat: 'repeat' })
      }
    })
  }
}

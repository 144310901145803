

















import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { ManualSubmission } from '@/components/SubmissionUpload/interfaces/submission.ts'
import { SelectFormGroup } from '@/components/SubmissionUpload/SubmissionForm/FormGroups'

@Component({
  components: { SelectFormGroup }
})
export default class Stream extends Vue {
  @Prop({ type: Object, required: true })
  readonly submission!: ManualSubmission

  isDirty = false

  get select() {
    return {
      selected: this.submission.stream,
      options: this.submission.inputOptions.streams.map(stream => stream.name)
    }
  }

  get validationState() {
    return typeof this.submission.stream === 'string'
  }

  @Watch('validationState')
  onValidationStateChanged(newVal: boolean) {
    this.$emit('validation-state-changed', newVal)
  }
}

const DataPermissions = Object.freeze({
  Submit: 'prd-datahub-portaal-zkh-aanleveren',
  Request: 'prd-datahub-portaal-org-afnemen'
})

const SubmissionStream = Object.freeze({
  Cataract: 'prd-datahub-cataract',
  'Multipel-Myeloom': 'prd-datahub-multipel-myeloom'
})

export const SecurityGroup = Object.freeze({
  Data: DataPermissions,
  Stream: SubmissionStream
})

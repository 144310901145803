import { AxiosInstance } from 'axios'
import { DatahubPortaalBadRequest } from '@/interfaces/datahub-portaal'
import { isAxiosErrorWithResponse } from '@/axios/utils/axios'
import store from '@/store/index'

export default function(instance: AxiosInstance) {
  instance.interceptors.response.use(
    response => response,
    error => {
      if (isAxiosErrorWithResponse(error)) {
        const { data }: { data: DatahubPortaalBadRequest } = error.response
        if (data.Type === 'not-authenticated-error') {
          store.commit('SET_AUTH_STATUS', false)
        } else if (data.Type === 'not-authorized-error') {
          store.commit('SET_AUTH_ERROR', data.Type ? data.Type : error)
        } else {
          store.commit('SET_LOCAL_ERROR', data.Type ? data.Type : error)
        }
      }

      return Promise.reject(error)
    }
  )
}

// If you want to support older IE, Android, and iOS device web browsers (https://bootstrap-vue.org/docs#js)
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'dhd-library/dist/dhd-library.css'
import './main.scss'

import { setupMsal } from 'dhd-auth'
import DHDLibrary from 'dhd-library'
import Vue from 'vue'

import {
  NavbarPlugin,
  PaginationPlugin,
  AlertPlugin,
  ModalPlugin,
  DropdownPlugin,
  LayoutPlugin,
  ImagePlugin,
  CardPlugin,
  ButtonPlugin,
  PopoverPlugin,
  ListGroupPlugin,
  LinkPlugin,
  BadgePlugin,
  TablePlugin,
  CollapsePlugin,
  FormGroupPlugin,
  FormPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  SpinnerPlugin,
  NavPlugin,
  TabsPlugin,
  ProgressPlugin,
  FormFilePlugin,
  InputGroupPlugin,
  FormDatepickerPlugin,
  FormCheckboxPlugin,
  TooltipPlugin,
  VBTooltip
} from 'bootstrap-vue'
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'
import { DatepickerOptions } from '@/config/datepicker'

import router from './router'
import store from './store'
import apolloProvider from './apollo'
import App from './App.vue'

import FontAwesomeIcon from 'dhd-library/src/icons'
import './filters'
import i18n from './i18n'
import { getEnvironmentVariable, Scopes } from '@/utils/environment'

Vue.config.productionTip = false

Vue.use(NavbarPlugin)
Vue.use(PaginationPlugin)
Vue.use(AlertPlugin)
Vue.use(ModalPlugin)
Vue.use(DropdownPlugin)
Vue.use(LayoutPlugin)
Vue.use(ImagePlugin)
Vue.use(CardPlugin)
Vue.use(ButtonPlugin)
Vue.use(PopoverPlugin)
Vue.use(ListGroupPlugin)
Vue.use(LinkPlugin)
Vue.use(BadgePlugin)
Vue.use(TablePlugin)
Vue.use(CollapsePlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormSelectPlugin)
Vue.use(SpinnerPlugin)
Vue.use(NavPlugin)
Vue.use(TabsPlugin)
Vue.use(ProgressPlugin)
Vue.use(FormFilePlugin)
Vue.use(InputGroupPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(TooltipPlugin)

Vue.use(DHDLibrary, { store })
Vue.use(AirbnbStyleDatepicker, DatepickerOptions)
Vue.directive('b-tooltip', VBTooltip)
Vue.component('font-awesome-icon', FontAwesomeIcon)

const msalConfig = {
  auth: {
    clientId: `${getEnvironmentVariable('VUE_APP_FRONTEND_CLIENT')}`,
    authority: `${getEnvironmentVariable(
      'VUE_APP_INSTANCE'
    )}/tfp/${getEnvironmentVariable(
      'VUE_APP_DOMAIN'
    )}/b2c_1a_signin`,
    knownAuthorities: [
      `${getEnvironmentVariable(
        'VUE_APP_INSTANCE'
      )}/tfp/${getEnvironmentVariable(
        'VUE_APP_DOMAIN'
      )}/b2c_1a_signin`
    ],
    redirectUri: window.location.origin
  }
}

const scopes = [Scopes.datahub, Scopes.user]

setupMsal(msalConfig, scopes).then(() => {
  new Vue({
    router,
    store,
    apolloProvider,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})

































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DatePicker extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: String, required: true })
  readonly id!: string

  @Prop({ type: String, required: true })
  readonly label!: string

  @Prop({ type: String, default: 'single' })
  readonly mode!: string

  @Prop({ type: String, default: '' })
  readonly dateOne!: string

  @Prop({ type: String, default: '' })
  readonly dateTwo!: string

  @Prop({ type: String, default: '' })
  readonly minDate!: string

  @Prop({ type: String, default: '' })
  readonly maxDate!: string

  @Prop({ type: Number, default: 1 })
  readonly months!: number

  setDateOne(value: string) {
    this.$emit('date-one-selected', value)
  }

  setDateTwo(value: string) {
    this.$emit('date-two-selected', value)
  }

  apply() {
    this.$emit('apply-selected')
  }

  clearDates() {
    this.setDateOne('')
    this.setDateTwo('')
    this.$emit('clear-selected')
  }

  btnOutlineClasses = ['btn', 'btn-outline-secondary', 'btn-sm', 'border']
  btnPrimaryClasses = ['btn', 'btn-primary', 'btn-sm']

  mounted() {
    // applying bootstrap button styles to buttons inside the date picker
    const element = document.getElementsByClassName('asd__action-buttons')[0]
    if (element) {
      const clearButton = element.firstElementChild
      if (clearButton) clearButton.classList.add(...this.btnOutlineClasses)
      const applyButton = element.lastElementChild
      if (applyButton) applyButton.classList.add(...this.btnPrimaryClasses)
    }
  }

  get display() {
    if (!this.dateOne) {
      return this.label
    } else if (this.dateOne && !this.dateTwo) {
      return this.dateOne
    } else if (this.dateOne && this.dateTwo) {
      return `${this.dateOne} t/m ${this.dateTwo}`
    } else {
      return this.label
    }
  }
}

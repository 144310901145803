
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class SplitScreenNavigation extends Vue {
  @Prop(String)
  readonly title!: string

  @Prop(String)
  readonly description!: string

  @Prop(String)
  readonly action!: string

  @Prop(String)
  readonly route!: string

  @Prop(String)
  readonly variant!: string
}

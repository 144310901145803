import format from 'date-fns/format'
import addYears from 'date-fns/add_years'
import subYears from 'date-fns/sub_years'
import { addDays } from 'date-fns'

const options = {
  locale: {
    code: 'nl'
  }
}

const dateFormat = 'YYYY-MM-DD'
const dateTimeText = 'YYYY-MM-DD HH:mm'

const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSS'

function dateYearAgo(date: number = Date.now()) {
  const subDate = subYears(date, 1)

  return format(subDate, dateFormat, options)
}

function dateToday(date: number = Date.now()) {
  return format(date, dateFormat, options)
}

function dateTomorrow() {
  const addedDate = addDays(Date.now(), 1)
  return format(addedDate, dateFormat, options)
}

function dateYearFromTomorrow() {
  const date = dateTomorrow()
  const addedDate = addYears(date, 1)
  return format(addedDate, dateFormat, options)
}

function dateYearAhead(date: number = Date.now()) {
  const addDate = addYears(date, 1)

  return format(addDate, dateFormat, options)
}

function dateTimeYearAgo(date: number = Date.now()) {
  const subDate = subYears(date, 1)

  return format(subDate, dateTimeFormat, options)
}

function dateTimeToday(date: number = Date.now()) {
  return format(date, dateTimeFormat, options)
}

function dateTimeYearAhead(date: number = Date.now()) {
  const addDate = addYears(date, 1)

  return format(addDate, dateTimeFormat, options)
}

function formatDate(date: string) {
  const parsedDate = new Date(date)
  return format(parsedDate, dateFormat)
}


function formatDateTime(date:string){
  const parsedDate = new Date(date)
  return format(parsedDate, dateTimeText)
}

export {
  dateYearAgo,
  dateToday,
  dateTomorrow,
  dateYearAhead,
  dateYearFromTomorrow,
  dateTimeYearAgo,
  dateTimeToday,
  dateTimeYearAhead,
  formatDate,
  formatDateTime
}

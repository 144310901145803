import {
  ActionTree,
  ActionContext,
  MutationTree,
  GetterTree,
  Store
} from 'vuex'
import { UserInfo } from '@/models'

// State
export interface RootState {
  initialized: boolean
  user: UserInfo | null
  isAuthenticated: boolean
  hospitalHasMultipleParts: boolean
  globalError: string | null
  localError: string | null
  authError: string | null
  hasUploadSubmitted: boolean
}

// Actions
export type RootActionContext = ActionContext<RootState, RootState>

export const enum ActionTypes {
  INITIALIZE_STATE = 'initializeState'
}

export interface RootActions extends ActionTree<RootState, RootState> {
  [ActionTypes.INITIALIZE_STATE]: (
    this: Store<RootState>,
    context: RootActionContext
  ) => void
}

// Mutations
export const enum MutationTypes {
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
  SET_AUTH_STATUS = 'SET_AUTH_STATUS',
  SET_GLOBAL_ERROR = 'SET_GLOBAL_ERROR',
  SET_LOCAL_ERROR = 'SET_LOCAL_ERROR',
  SET_AUTH_ERROR = 'SET_AUTH_ERROR',
  SET_UPLOAD_SUBMITTED = 'SET_UPLOAD_SUBMITTED'
}

export interface RootMutations extends MutationTree<RootState> {
  [MutationTypes.SET_INITIAL_STATE]: (
    state: RootState,
    values: { user: UserInfo, hospitalHasMultipleParts: boolean, hasUploadSubmitted: boolean}
  ) => void
  [MutationTypes.SET_UPLOAD_SUBMITTED]: (
    state: RootState,
    hasUploadSubmitted: boolean
  ) => void
  [MutationTypes.SET_AUTH_STATUS]: (
    state: RootState,
    isAuthenticated: boolean
  ) => void
  [MutationTypes.SET_GLOBAL_ERROR]: (state: RootState, error: string) => void
  [MutationTypes.SET_LOCAL_ERROR]: (state: RootState, error: string) => void
  [MutationTypes.SET_AUTH_ERROR]: (state: RootState, error: string) => void
}

// Getters
export const enum GetterTypes {}

export type RootGetters = GetterTree<RootState, RootState>

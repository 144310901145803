export const DatepickerOptions = Object.freeze({
  dateLabelFormat: 'DD-MM-YYYY',
  days: [
    'Maandag',
    'Dinsdag',
    'Woensdag',
    'Donderdag',
    'Vrijdag',
    'Zaterdag',
    'Zondag'
  ],
  daysShort: ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'],
  monthNames: [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December'
  ],
  colors: {
    selected: '#0076b7',
    inRange: '#54A3CF',
    selectedText: '#fff',
    text: '#565a5c',
    inRangeBorder: '#0076b7',
    disabled: '#fff',
    hoveredInRange: '#b5d9ea'
  },
  texts: {
    apply: 'Toepassen',
    cancel: 'Selectie Wissen'
  }
})

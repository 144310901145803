var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"history my-3"},[_c('b-col',[_c('b-card',[_c('b-card-title',{attrs:{"title-tag":"h5"}},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('b-badge',{staticClass:"ml-2",attrs:{"variant":"neutral"}},[_vm._v(_vm._s(_vm.visibleItems)+"/"+_vm._s(_vm.totalItems))]),_c('b-btn',{staticClass:"float-right",attrs:{"to":{
            path: ("/aanlevering/" + (_vm.isSubmitted ? 'aangeleverd' : 'verwacht')),
            query: _vm.isSubmitted
              ? _vm.failedSubmissionStatus
              : _vm.expectedSubmissionRange
          },"variant":"primary","size":"sm"}},[_vm._v("Volledig overzicht")])],1),_c('hr',{staticClass:"my-3"}),_c('b-table',{staticClass:"mt-3",attrs:{"id":(_vm.title + "-table"),"sticky-header":"100vh","head-variant":_vm.tableHeadVariant,"table-variant":_vm.tableVariant,"no-border-collapse":"","bordered":"","hover":"","small":"","show-empty":"","empty-text":_vm.emptyText,"items":_vm.items,"fields":_vm.isSubmitted ? _vm.submittedFields : _vm.expectedFields,"sort-by":_vm.sortBy,"sort-desc":_vm.isDescending,"no-sort-reset":true},scopedSlots:_vm._u([{key:"cell(disCode)",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"head(disCode)",fn:function(row){return [_vm._v(" "+_vm._s(row.label)+" "),_c('submission-popover',{attrs:{"content":_vm.$t('views.SubmissionOverview.columnHeaders.instellingonderdeel'),"useTableColor":true}})]}},{key:"cell(deadline)",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("date")(value))+" ")]}},{key:"cell(submissionDate)",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("date")(value))+" ")]}},{key:"cell(status)",fn:function(ref){
          var value = ref.value;
return [_c('submission-status-circle',{attrs:{"status":value}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(value))])]}},{key:"head(status)",fn:function(row){return [_vm._v(" "+_vm._s(row.label)+" "),_c('submission-popover',{attrs:{"content":_vm.$t('views.SubmissionOverview.columnHeaders.status'),"useTableColor":true}})]}},{key:"cell(details)",fn:function(row){return [_c('b-btn',{attrs:{"size":"sm","variant":"outline-primary","to":("/aanlevering/details/" + (row.item.id))}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"info-circle"}}),_vm._v("Details")],1)]}},{key:"head(details)",fn:function(row){return [_vm._v(" "+_vm._s(row.label)+" "),_c('submission-popover',{attrs:{"content":_vm.$t('views.SubmissionOverview.columnHeaders.details'),"useTableColor":true}})]}},{key:"cell(upload)",fn:function(){return [_c('b-btn',{attrs:{"size":"sm","variant":"primary","to":"/aanlevering/aanleveren"}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"file-upload"}}),_vm._v("Aanleveren")],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }